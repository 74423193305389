import Player from '@vimeo/player';

const toDataUrl = async function (item, callback) {
   //Convert to base64
   let url = item.url;
   return new Promise((resolve, reject) => {
     var xhr = new XMLHttpRequest();
     xhr.onload = function () {
       var reader = new FileReader();
       reader.onloadend = function () {
         resolve(reader.result);
       };
       reader.readAsDataURL(xhr.response);
     };
     xhr.onerror = () => {
         $(item).remove();
         reject({
            // status: this.status,
            statusText: xhr.statusText,
         });
     };
     xhr.open("GET", url);
     xhr.withCredentials = true;
     xhr.responseType = "blob";
     xhr.send();
   });
 };

export default class Entry {
   constructor(row, headers) {
      const t = this;

      this.fetchedBibliography = [];

      for(var i = 0; i < headers.length; i++) {
         let key = headers[i];
         key = key.replace(/[^a-zA-Z0-9\s]+/g, '').replaceAll(' ','-').toLowerCase();
         this[key] = row.data[i];
         if(this['type']){
            // console.log(this.type, this.title);
            this._cat = this['type'];
            // category;
            if(key=='type') {
               // console.log(row.data[i], row);
            }
         }
         // this.prototype[headers[i]] = this.data[i];
      }

      this.relatedBoxes = [];
      window.relators = [];
 
      // if(this['status'] !== 'Draft') {
      //    this.createBlock();
      // } else {
      //    console.log(`DRAFT: "${this['title']}" - skipping`);
      // }
      this.closeRelateds = this.closeRelateds.bind(this);
      this.clearRelateds = this.clearRelateds.bind(this);
      this.alignRelatedBoxes = this.alignRelatedBoxes.bind(this);
      // this.drawLine = this.drawLine.bind(this);
   }

   createBlock(idx){
      const t = this;
      // if($(`.boxes.${this._cat}`).length==0){
      //    if(this._cat) {
      //       $('main').append(`<content class='boxes ${this._cat}'><div class='boxes-inner'><div class='box-backdrop'></div></div></content>`);
      //       $('nav.cats').prepend(`<a href='#${this._cat}'>${this._cat}</a>`);
      //    }
      // }

      let boxCss = '';
      let boxClass = '';

      let title = '';
      let authors = '';
      let figure = '';
      let embed = '';
      let sources = [];
      let sourcesObj = {};
      let sourcesResult = null;
      let isbn_dois = '';
      let relateds = [];
      let strengths = [];
      let year = '';
      let medialink = '';
      let figcaption = '';

      window.mouseovers = [];
      window.mouseleaves = [];

      // console.log(this);

      title = 
         this['title'] ||
         this['concept-bold-text-represents-key-concepts'] ||
         this['artwork-title'] ||
         this['concept-name'];

      var important = this['important'] == 'Y';
      if(this['status'] == 'Draft') important = false;
      if(!important) boxClass += 'unimportant';
      if(!important) return false;
      
      if(!title) return false;

      Object.keys(this).forEach(key => {
         if(key.indexOf('description')>=0) {
            this.description = this[key];
         }

         if(key.indexOf('author')==0) {
            if(this[key]) {
               if(authors) authors += ', ';
               authors += this[key];
            }
         }

         if(key.indexOf('year')==0) {
            if(this[key]) {
               if(year) year += ', ';
               year += this[key];
            }
         }

         if (key.startsWith("source-") && key.indexOf('author')>=0) {
            // console.log(key);
            const parts = key.split("-");
            const sourceIndex = parts[1];
            const authorIndex = parts[3];
            const namePart = parts[4]; // 'first' or 'last'
            const value = this[key];

            if(!value) return;

            // console.log(parts);
            
            if (!sourcesObj[sourceIndex]) {
               sourcesObj[sourceIndex] = [];
            }
            
            if (!sourcesObj[sourceIndex][authorIndex - 1]) {
               sourcesObj[sourceIndex][authorIndex - 1] = { firstName: "", lastName: "" };
            }
            
            if (namePart === "first") {
               sourcesObj[sourceIndex][authorIndex - 1].firstName = value;
            } else if (namePart === "last") {
               sourcesObj[sourceIndex][authorIndex - 1].lastName = value;
            }

            sourcesResult = Object.keys(sourcesObj).map(sourceIndex => ({
               source: `Source ${sourceIndex}`,
               authors: sourcesObj[sourceIndex]
            }));

            // console.log('sourcesObj', sourcesResult);
         } else if (key.indexOf('source-')==0) {
            if(this[key]) {
//               console.log(`this`,key, this[key]);
               sources.push(this[key]);
            }
         }

         this.sources = sources;

         if(key.indexOf('isbn-doi')==0) {
            if(this[key]) {
               // console.log(this[key]); // isbn 
               if(isbn_dois) isbn_dois += ', ';
               isbn_dois += this[key];
            }
         }

         if(key.indexOf('related')==0){
            if(this[key]) {
               relateds.push(this[key]);

               // also get strength value
               // "strength 1" is for "related concept 1"
            }
         }
         if(key.toLowerCase().indexOf('strength')==0){
            if(this[key]) {
               strengths.push(this[key]);
            }
         }

         if(key.indexOf('media-link')==0) {
            if(this[key]) {
               medialink += this[key];
            }
         }
      });

      this._relateds = relateds;
      this._strengths = strengths;

      // mostly replace AUTHORS with ISBN/DOI
      // if(this['isbn'] || this['doi']) {
      //    authors = '';
      //    if(this['isbn']) authors += `<a href='https://www.worldcat.org/isbn/${this['isbn']}' target='_blank'>ISBN: ${this['isbn']}</a>`;
      //    if(this['doi']) authors += `<a href='https://doi.org/${this['doi']}' target='_blank'>DOI: ${this['doi']}</a>`;
      // }

      
      if(this['image-link']){
         // figure = `<img src="${this['image-link']}">`;
         $(figure).parents('.box').addClass('has-image');
         figure = new Image();
         figure.src = this['image-link'];
         figure.onerror = function(){
            $(`img[src="${figure.src}"]`).parents('.box').removeClass('has-image');
        }

         if(this['image-caption']){
            figcaption = `<figcaption>${this['image-caption']}</figcaption>`;
         }
      }

      if(this['video-link']){
      //    this.createBlock();
         embed = '';
         // if(this['video-link'].indexOf('youtube')>=0 || this['video-link'].indexOf('youtu.be')>=0) {
         if(this['video-link'].indexOf('youtube')>=0) {
            var embedElement = document.createElement('iframe');

            if(this['video-link'].split('v=').length<2) return;

            let parseYoutubeId = this['video-link'].split('v=')[1];
            let ampersandPosition = parseYoutubeId.indexOf('&');
            if(ampersandPosition != -1) {
               parseYoutubeId = parseYoutubeId.substring(0, ampersandPosition);
            }
            let youtubeUrl = `https://www.youtube.com/embed/${parseYoutubeId}?enablejsapi=1`;
            embedElement.videoId = parseYoutubeId;
            embedElement.src = youtubeUrl;
            embedElement.type = 'youtube';
            
         } else if(this['video-link'].indexOf('vimeo')>=0) {
            var embedElement = document.createElement('iframe');
            let parseVimeoId = this['video-link'].split('.com/')[1];
            
            if(parseVimeoId.indexOf('/')>=0) {
               parseVimeoId = parseVimeoId.replaceAll('/','?h=');
            }

            let vimeoUrl = `https://player.vimeo.com/video/${parseVimeoId}`;
            embedElement.src = vimeoUrl;
            embedElement.videoId = parseVimeoId;
            embedElement.type = 'vimeo';
         }

         if(embedElement) {
            embed = `<iframe></iframe>`;
            embedElement.dataset.url = this['video-link'];
            embedElement.width = 640;
            embedElement.height = 360;
            embedElement.frameborder = 0;
            embedElement.allow = 'autoplay; fullscreen';
            embedElement.allowfullscreen = true;
            this._embedElement = embedElement;

            $(figure).parents('.box').addClass('has-image');
            let thumbId = embedElement.videoId.replaceAll('?h=',':');
            let figureImg = new Image();
            figureImg.src = `https://vumbnail.com/${thumbId}.jpg`
            figureImg.onerror = function(){
               // $(`img[src="${figureImg.src}"]`).parents('.box').removeClass('has-image');
            }

            embedElement.dataset.thumb = figureImg.src;
            
            if(!this['image-link']) {
               figure = document.createElement('div');
               figure.className = 'videothumb';
               figure.innerHTML = figureImg.outerHTML;
               figureImg.onerror = function(){
                  $(`img[src="${figureImg.src}"]`).parents('.box').removeClass('has-image');
               }
            }
         }
      }

      // sources
      // this._sources = [];
      // Object.keys(this).forEach(key => {
      //    if(key.indexOf('source')==0) {
      //       console.log(key);
      //       let sourceNum = key.split('source-')[1][0];
      //       if(sourceNum) {
      //          var sourceObj = {
      //             author_1 : this[`source-${sourceNum}-author-1-first-name`] + ' ' + this[`source-${sourceNum}-author-1-last-name'],
      //          };
      //          this._sources[sourceNum].push(sourceObj);
      //       }
      //    }
      // });

      let MAIN_html = '';
      let ASIDE_html = '';
      let contributor = '';

      title = title.replaceAll('<', '&lt').replaceAll('>', '&gt');
      authors = authors.replaceAll('<', '&lt').replaceAll('>', '&gt');

      if(!sourcesResult) {
         let sourcesHtml = '';
         sources.forEach((source, i) => {
            // source = source.replaceAll('<', '&lt').replaceAll('>', '&gt');
            if(source.indexOf('http')==0) {
               sourcesHtml += `<a href='${source}' style="cursor:pointer!important;" onclick="window.open('${source}')">${source}</a>`;
            } else {
               sourcesHtml += `<span>${source}</span>`;
            }
            // sourcesHtml += sources.replaceAll('<', '&lt').replaceAll('>', '&gt');
         });
      } else {
         let sourcesObjHtml = '';
         sourcesResult.forEach((source, i) => {
            sourcesObjHtml += `<div class='source'>`;
            // sourcesObjHtml += `<div class='source'><span class='source-title'>${source.source}</span>`;
            source.authors.forEach((author, i) => {
               sourcesObjHtml += `<span class='source-author'>${author.lastName}${author.lastName&&author.firstName?', ':''}${author.firstName}</span>`;
            });
            sourcesObjHtml += `</div>`;
         });
      }

      if(embed) MAIN_html += `<div class='embed'>${embed}</div>`;
      if(this.description) MAIN_html += `<p class='desc'>${this.description.replaceAll('\n\n','<br><br>')}</p>`; 

      if(isbn_dois){
         var fetchUrls = '';
         var isbnDoisArray = isbn_dois.split(',');
         for(var i=0; i<isbnDoisArray.length; i++) {
            isbnDoisArray[i] = isbnDoisArray[i].trim();
            var fetchUrl = false;
            var DOI=null;
            var ISBN=null;
            if(isbnDoisArray[i].indexOf('doi')>=0) {
            // if(isValidDoi(isbnDoisArray[i]) || isbnDoisArray[i].indexOf('doi')>=0) {
               DOI = isbnDoisArray[i].split('doi.org/')[1];
               fetchUrl = `https://api.crossref.org/works/${DOI}`;
            } else if(isValidIsbn(isbnDoisArray[i]) || isbnDoisArray[i].indexOf('isbn')>=0) {
               ISBN = isbnDoisArray[i];
               fetchUrl = `https://openlibrary.org/api/books?bibkeys=ISBN:${ISBN}&format=json&jscmd=data`;
            } else if(isValidUrl(isbnDoisArray[i])) {
               fetchUrl = isbnDoisArray[i];
            } else {
               fetchUrl = isbnDoisArray[i]; 
            }
            if(ISBN) t.isbn = ISBN;
            if(DOI) t.doi = DOI;
            if(fetchUrl) t.isError = true;
            fetchUrls += `<p
               data-fetchurl='${fetchUrl}'
               ${ISBN!=null?"data-isbn='"+ISBN+"'":''}"
               ${DOI!=null?"data-doi='"+DOI+"'":''}"
               data-id='${idx}'
               onclick="window.open('${fetchUrl}')" href="${fetchUrl}"
               class='${!ISBN&&!DOI?'':'load'}'
               ><span class='fetchurl'>${isbnDoisArray[i]}</span></p>`
            ;
            // add id to parent
            // $(t._box).attr('data-id', idx);
         }
         if(fetchUrl){
            ASIDE_html += `<div class='references'>${fetchUrls}</div>`;
         }
      }

      // split links by comma
      if(this.link){
         this.links = this.link.split(',');
         ASIDE_html += `<div class='links'>`;
         for(var i=0;i<this.links.length;i++) {
            ASIDE_html += `<a onclick="window.open('${this.links[i]}')" href="${this.links[i]}">${this.links[i].trim()}</a>`;
         }
         ASIDE_html += `</div>`;
      }

      if(this.contribution && this.contribution=='TRUE'){
         contributor += `<div class='contributed by'>`;
         contributor += `${this['contributor-first-name']} ${this['contributor-last-name']}`;
         if(this['title-of-contributor']) contributor += `<span>${this['title-of-contributor']}</span> `;
            if(this['link-to-contributor']) contributor += `<a href="${this['link-to-contributor']}" onclick="window.open('${this['link-to-contributor']}')">${this['link-to-contributor']}</a>`;
         contributor += `</div>`;
      }

      // media link
      if(medialink){
         ASIDE_html += `<div class='media links'>`;
         // for(var i=0;i<this.links.length;i++) {
            ASIDE_html += `<a onclick="window.open('${medialink}')" href="${medialink}">${medialink.trim()}</a>`;
         // }
         ASIDE_html += `</div>`;
      }

      let status = `[#${idx}]`;
      /*
      let status = '';
      if(this.description) status += '<i class="desc">D</i>';
      if(authors) status += '<i class="authors">A</i>';
      if(sourcesHtml!='') status += '<i class="sources">S</i>';
      // if(sourcesObjHtml!='') status += '<i class="scholars">Sch</i>';
      if(relateds.length>0) status += `<i class="has-related">R${relateds.length-1}</i>`;
      if(embed) status += '<i class="embed">E</i>';
      */

      // ${ this._cat ? `<div class='type'>${this._cat}</div>` : ``}

      let marginVal = 200;
      let marginValX = 200;
      // let margins = `margin: ${Math.random()*marginVal}px ${Math.random()*marginVal}px ${Math.random()*marginVal}px ${Math.random()*marginVal}px;`;
      let margins = `margin: ${Math.random()*marginVal + 10}px ${Math.random()*marginVal + 10}px ${Math.random()*marginVal + 10}px ${Math.random()*marginValX + 10}px;`;
      boxCss += margins;

      // create a slug from title
      let slug = title.toLowerCase().replaceAll(' ','-').replaceAll('.','').replaceAll(',','').replaceAll(':','').replaceAll(';','').replaceAll('!','').replaceAll('?','').replaceAll('(','').replaceAll(')','').replaceAll('/','').replaceAll('"','').replaceAll("'",'').replaceAll('&','').replaceAll('’','').replaceAll('‘','').replaceAll('“','').replaceAll('”','').replaceAll('—','-').replaceAll('–','-');
      this._slug = slug;

      

      var box = $(`
         <article 
            class='box ${this._cat} ${boxClass}' 
            data-istheory="${this['quoted-in-dissertation']=='Y'}"
            data-id="${idx}"
            data-cat='${this._cat}'
            data-theme='${this['theme']}' 
            data-relateds="${relateds.length}" 
            id='${this._slug}' 
            style='${boxCss}'
            data-tooltip='Mouseover to show connecting entries, click to open'
         >
         ${ figure ? `<figure>${figure.outerHTML}${figcaption}</figure>` : ``}
            <h3 class='title'>${title}</h3>
            ${ authors ? `<div class='authors'>${authors}</div>` : ``}
            <div class='box-meta'>
               ${ year ? `<div class='year'>${year}</div>` : ``}
               ${ this['theme'] ? `<div class='theme'>${this['theme']}</div>` : ``}
            </div>
            ${/* <div class='box-body'> */ ''}
               <div class='box--main box-content'>${MAIN_html}</div>
               <div class='box--aside box-content'>${ASIDE_html}</div>
            ${/* </div> */ ''}
            <div class='status'>${status}</div>
            ${contributor}
            <a href='#close' class="close"></a>
            </article>
         `);
            
      this._box = box;

      $(`.boxes .boxes-inner`).append(box);
      // $(`.boxes.${this._cat} .boxes-inner`).append(box);

      if(figure) {
         $(box).addClass('has-image');

         $(box).find('img').on('error', function(){
            $(box).removeClass('has-image');
            if($('.view-as').val()=='images') {
               $(box).css('display','none');   
            }
         }).on('load', function(){
            // if(window.isotopes){
            //    // clearTimeout(window.removeBlur);
            //    clearTimeout(window.doIsotope);
            //    window.doIsotope = setTimeout(function(){
            //       // $('body').addClass('blur');
            //       window.isotopes[0].layout();
            //       // console.log('ISO ARRANGEMENT BY IMG LOADED');
            //       // window.removeBlur = setTimeout(function(){
            //       $('body').removeClass('blur');
            //       // }, 1000);
            //    }, 21);
            // }
         });
      }
      
      window.entries.legitEntries.push(this);

      this.initRelateds = this.initRelateds.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.centerBox = this.centerBox.bind(this);
      // this.getReferences = this.getReferences.bind(this);
      this.setVideoInterval = this.setVideoInterval.bind(this);
      this.initYoutubePlayer = this.initYoutubePlayer.bind(this);
      this.bindBox();
   }

   initRelateds() {
      const t = this;
      
      t.totalRelatedHeight = 0;
      
      if(!t._relateds) return;

      t._relateds.forEach((key, index) => {
         
         // Assuming t._relateds contains the keys related to '.box h3' text
         $('.box h3').each(function() {
            if ($(this).text() === key) {
               var box = $(this).closest('.box');
               if (t.relatedBoxes.indexOf(box.get(0)) === -1) {
                  t.relatedBoxes.push(box.get(0));
                  t.totalRelatedHeight += $(box).outerHeight(false);
               }
               // Temporarily setting visibility to hidden to calculate the total height without displaying the boxes
               $(box).css('visibility', 'hidden');
               $(box).attr('data-strength', t._strengths[index]);
               $(box).css('visibility', '');
            }
         });
      });
      // }
      
      if(t.relatedBoxes.length==0) {
         t.relatedBoxes = false;
         $(t._box).addClass('no-rel');
      } else {
         $(t._box).addClass('has-rel');
      }
   }

   closeRelateds() {
      const t = this;
      // closes the relateds for this box
      this.relatedBoxes && this.relatedBoxes.forEach(function(box, index) {
         $(box).removeClass('related related-fly');
         // if($(box).attr('data-origpos')) {
            // $(box).css({
               // 'top': $(box).attr('data-origpos').split(' ')[0],
               // 'left': $(box).attr('data-origpos').split(' ')[1],
            // });
         // }
      });

      $(t._box).removeClass('hovering');

      window.relators.splice(window.relators.indexOf(this), 1);

      // t.mouseleaves.splice(window.relators.indexOf(this), 1);
      if(window.relators.length==0) {
         this.clearRelateds();
      } else {
         // window.relators[0].centerBox(window.relators[window.relators.length-1]._box[0]);
         window.relators.forEach(rel => {
            rel.alignRelatedBoxes(rel._box);
         });
         // setTimeout(() => {
            // t.drawLine();
         // }, 50);
      }
   }

   clearRelateds(){
      $('.related').removeClass('related related-fly');
      $('.hovering').removeClass('hovering');
      $('body > main').removeClass('relating');
      $('.footer').addClass('in');
      clearTimeout(window.alignTimeout);
      window.relators = [];
      window.mouseovers = [];
      window.mouseleaves = [];
      // $('#lines-canvas').addClass('out');
      // setTimeout(function(){
      if(window.mainView.context) {
         window.mainView.context.clearRect(0, 0, window.mainView.canvas.width, window.mainView.canvas.height);
      }
         // $('#lines-canvas').removeClass('out');
      // }, 200);
   }

   initYoutubePlayer() {
      const t = this;
      if(window.YT && window.YT.Player) {
         this._player = new YT.Player(t._embedElement, {
            origin: window.location.origin,
            videoId: t._embedElement.videoId,
            playerVars: {
              'playsinline': 1
            },
            events: {
            }
          });
      } else {
         // repeat this function until YT is defined
         setTimeout(this.initYoutubePlayer, 200);
      }
   }

   centerBox(el) {
      const main = document.querySelector('main');
      const boxes = document.querySelector('.boxes');
      const boxesInner = document.querySelector('.boxes-inner');
      const box = el;
      
      if (!main || !boxes || !boxesInner || !box) return;

      clearTimeout(window.isoTimeout);
      
      // Get the dimensions and positions of the box and boxes-inner container
      const boxRect = box.getBoundingClientRect();
      const boxesInnerRect = boxesInner.getBoundingClientRect();
      
      // Get the computed styles for the main element
      const mainStyle = window.getComputedStyle(main);

      function getScale(el) {
         let div = $(el).css('transform');
         var values = div.split('(')[1];
         values = values.split(')')[0];
         values = values.split(',');
         var a = values[0];
         var b = values[1];
         var scale = Math.sqrt(a*a + b*b);
         return scale;
      }
      
      // Extract the scaling factor from the 'transform' style of the main element
      const mainScaleMatch = getScale('main'); // mainStyle.transform.match(/scale\(([^)]+)\)/);
      const mainScale = 1; //mainScaleMatch ? mainScaleMatch : 1;
      
      // Get the center of the box relative to the viewport
      const boxCenterX = boxRect.left + boxRect.width / 2;
      const boxCenterY = boxRect.top + boxRect.height / 2;
      
      // Get the center of the viewport
      const viewportCenterX = window.innerWidth / 2;
      const viewportCenterY = window.innerHeight / 2;
      
      // Calculate the offset required to center the box
      let offsetX = (viewportCenterX - boxCenterX) / mainScale;
      let offsetY = (viewportCenterY - boxCenterY) / mainScale;
      
      // Adjust the offset to account for the fact that .boxes is centered with top: 50%, left: 50% and transform: translate3d(-50%, -50%, 0)
      const boxesCenterX = boxesInnerRect.left + boxesInnerRect.width / 2;
      const boxesCenterY = boxesInnerRect.top + boxesInnerRect.height / 2;
      
      offsetX += (boxesCenterX - viewportCenterX) / mainScale;
      offsetY += (boxesCenterY - viewportCenterY) / mainScale;
      
      // Apply the necessary translation to the viewport
      window.mainView.state.translateX = offsetX;
      window.mainView.state.translateY = offsetY;
      // window.mainView.state.scale = 0.8;
      window.mainView.updateTransform();
   }

   bindBox() {
      var t = this;

      $(this._box).on('mouseenter', function(e){
         e.preventDefault();
         
         if(window.preventClick) { return false; }
         // if($('.box-open').length>0) { return false; }
         
         const _this = this;
         
         // if(window.doubleClicked) { return false; }
         
         window.mouseovers.forEach(e => {
            clearTimeout(e);
         });

         if($(this).hasClass('no-hover')) return;
         
         t.mouseenter = setTimeout(function(){
            if(window.relators.indexOf(t) < 0) {
               window.hoverbox = _this;
               window.lastbox = t;
               
               if(t.relatedBoxes.length>0) {
                  window.ux.guideTexts.mouseover.classList.add('ok');

                  t.alignRelatedBoxes(t._box);
                  $(hoverbox).addClass('hovering');
                  $('body > main').addClass('relating');
                  // -----------------------------------------------------------------------------
                  window.relators.push(t);
                  // t.drawLine();
               }
            }

         }, $(this).hasClass('hovering') ? 10 : 1500);
         
         window.mouseovers.push(t.mouseenter);
         
      }).on('mouseleave', function(){
         window.mouseovers.forEach(e => {
            clearTimeout(e);
         });
         window.mouseovers = [];
         $(this).removeClass('no-hover');
      }).on('click', function(e) {
         const _this = this;
         // console.log(t);
         e.stopPropagation();
         e.preventDefault();

         // setTimeout(() => {
         //    // window.doubleClicked = false;
         //    if(!$(_this).hasClass('hovering')){
         //       window.preventClick = false;
         //       $(_this).trigger('mouseenter');
         //    }
         // }, 200);

         if(!window.preventClick) {
            window.preventClick = true;
            if($(this).hasClass('open')) {
               return;
            }
            // t.centerBox(this);
            // let isProperZoom = window.mainView.state.scale==0.8;
            let isProperZoom = true;
            // window.mainView.state.scale = 0.8;
            // window.mainView.updateTransform();
            t.handleClick(_this);
            t.centerBox(_this);
            
            if(!isProperZoom){
               setTimeout(function(){
                  // if(window.miniMap) window.miniMap.update();
                  t.centerBox(_this);
                  window.preventClick = false;
               }, 500);
            } else {
               setTimeout(function(){
                  clearTimeout(window.isoTimeout);
               }, 100);
               setTimeout(function(){
                  
                  // if(window.miniMap) window.miniMap.update();
                  window.preventClick = false;
               }, 500); 
            }
         }
      });

      $(this._box).find('.close').on('click', function(e){
         clearTimeout(window.alignTimeout);

         window.preventClick = true;
         // setTimeout(function(){
            e.preventDefault();
            
            $('.box-content').hide();
            // $('.box-content').slideUp(80);
            $(t._box).removeClass('open');
            if(t._player) t._player.pause ? t._player.pause() : t._player.pauseVideo!==undefined ? t._player.pauseVideo() : '';
            if($('.box.open').length == 0) {
               $('main').removeClass('box-open');
               $('.footer').addClass('in');
               
               if(window.relators.length == 0) {
                  $('.hovering').removeClass('hovering');
                  $('body > main').removeClass('relating');
               } else if(window.relators.length > 0) {
                  for(var i=0; i<window.relators.length; i++) {
                     $(window.relators[i]._box).addClass('hovering');
                     $('body > main').addClass('relating');
                  }
               } else {
                  t.closeRelateds();
                  // $('.hovering').removeClass('hovering');
                  // $('body > main').removeClass('relating');
               }
            }
            setTimeout(function(){
               window.preventClick = false;

               if($('.filtered').length>0 && window.ux){
                  $('.footer').addClass('in');
                  window.ux.applyFilters();
                  clearTimeout(window.isoTimeout);
               }
            }, 200);

            // window.relators.splice(window.relators.indexOf(t), 1);

            for(var i=0;i<window.relators.length;i++) {
               $(window.relators[i]._box).addClass('hovering');
               if(window.relators[i]){
                  window.relators[i].alignRelatedBoxes(window.relators[i]._box);
               }
             }
      });
   }

   handleClick(el) {
      const t = this;
      // const el = el;
      // $('.box.open').removeClass('open');
      if($('.box-open').length>0) { 
         $('.box.open .close').click();
      }
      $('body > main').addClass('relating');
      
      window.ux.guideTexts.open.classList.add('ok');

      // clearTimeout(window.flyDelay);
      clearTimeout(t.mouseleave);
      
      // setTimeout(function(){
      
      window.log.add(t);
      
      $(el).addClass('open').removeClass('related');
      
      window.lastbox = t;
      window.hoverbox = el;
      
      $('.box.open .box-content').show();
      
      if($('.box.open .box-content').text().trim() == '') {
         $('.box-content').hide();
      }
      
      $('main').addClass('box-open relating');
      
      window.relators.push(lastbox);
      
      $(el).addClass('open').removeClass('related');
      // }
      
      this.setVideoInterval(t, el);
      
      // get References
      // this.getReferences(t, el);
      window.bib.getReferenceOpenBox();
      
      for(var i=0;i<window.relators.length;i++) {
         window.relators[i].alignRelatedBoxes(window.relators[i]._box);
      }
      
      $(el).removeClass('related');
   }

   /*
   getReferences(t, el) {
      console.log('getReferences', t, el);
      if($(el).find('[data-fetchurl]').length>0) {
         // setTimeout(function(){
            
            $(el).find('[data-fetchurl]').addClass('load');
            $(el).find('[data-fetchurl]').each(function(){
               const __this = this;
               let fetchUrl = $(this).attr('data-fetchurl');
               fetch(fetchUrl)
               .catch(error => {
                  console.error('Error:', error);
                  $(__this).hide();
               })
               .then(response => response.json())
               .then(data => {
                  if(Object.keys(data).length==0){
                     $(__this).hide();
                     return;
                  }

                  $(__this).removeAttr('data-fetchurl');
                  
                  window.fetched = data;
                  // DOI
                  if(fetchUrl.indexOf('crossref')>=0 && data.message) {
                     let names = '';
                     if(data.message.author){
                        for(var i=0;i<data.message.author.length;i++) {
                           names += data.message.author[i].family + ', '+data.message.author[i].given;
                           if(i == data.message.author.length-2) {
                              names += ', and ';
                           } else if(data.message.author.length>2) names += ', ';
                        }
                     }
                     let dateparts = data.message.issued['date-parts'][0];
                     let fullString = `<p>${names}. "${data.message.title[0]}." ${data.message['short-container-title']}, vol. ${data.message.volume}, no. ${data.message.issue}, ${dateparts[2]}.${dateparts[1]}.${dateparts[0]}, pp. ${data.message.page}. ${data.message.publisher}, doi: <a href="${data.message.URL}" onclick="window.open('${data.message.URL}')">${data.message.DOI}</a>.</p>`;
                     t.fetchedBibliography.push(data.message);
                     $(__this).html(fullString);
                     
                     // ISBN
                  } else if(fetchUrl.indexOf('openlibrary')>=0 && data) {
                     Object.keys(data).forEach(key => {
                        let entryData = data[key];
                        
                        let names = '';
                        for(var i=0;i<entryData.authors.length;i++) {
                           if(entryData.authors[i].name.split(' ').length>=3) {
                              names += entryData.authors[i].name;
                           } else {
                              names += entryData.authors[i].name.split(' ')[entryData.authors[i].name.split(' ').length-1] + ', '+entryData.authors[i].name.split(' ')[0];
                           }
                           if(i == entryData.authors.length-2) {
                              names += ', and ';
                           } else if(entryData.authors.length>2 && i < entryData.authors.length-1) names += ', ';
                        }
                        let fullString = `<p>${names}. <em>${entryData.title}</em>. ${entryData.publishers[0].name}, ${entryData.publish_date}. <a href="${entryData.url}')" onclick="window.open('${entryData.url}')">${key}</a>.</p>`;
                        t.fetchedBibliography.push(entryData);
                        $(__this).html(fullString);
                     });
                  }
                  
                  if($('.bib-open').length < 0) {
                     $(__this).removeClass('load');
                     $(__this).show();
                  }

                  console.log('Reference fetched');
                  // $(this).slideDown(300);
               });
            }); // each
         // }, 50);
      } // data-fetchurls
   }
   */
  

   setVideoInterval(t, el) {
      var videoInterval = setInterval(function(){
         // get video player
         if(t._embedElement) {
            if(t._embedElement.type=='youtube') {
               t.initYoutubePlayer();
               // t._player = new YouTubePlayer(t._embedElement);
               // t._player.loadVideoById(t._embedElement.videoId);
               // t._embedElement.player = t._player;
            } else if(t._embedElement.type=='vimeo') {
               t._player = new Player(t._embedElement);
               t._player.getVideoHeight().then(h => {
                  t._player.getVideoWidth().then(w => {
                     let ratio = (h/w) * 100;
                     $(t._embedElement).parent().css('padding-bottom', ratio+'%');
                  });
               });
            }
            $('.box.open .box--main iframe').replaceWith(t._embedElement);
            let newImage = new Image();
            newImage.src = t._embedElement.dataset.thumb;
            newImage.className = 'playerthumb';
            $(newImage).appendTo($('.box.open .box--main .embed'))
            newImage.onload = async function(){
               try {
                  newImage.src = await toDataUrl(newImage);
               } catch (error) {
                  $(newImage).remove();
               }
            }
            
            clearInterval(videoInterval);
         } else {
            // no embedElement, remove interval
            clearInterval(videoInterval);
         }
         
         if(t._player) {
            // if(t._player) t._player.pause ? t._player.pause() : t._player.pauseVideo();
            clearInterval(videoInterval);
            
            setTimeout(function(){
               // $(box).find('.box-content').hide().slideDown(300);
               t._player.play ? t._player.play() : t._player.playVideo();
               t._player.mute ? t._player.mute() : t._player.setVolume(0); // setVolume for Vimeo
            }, 100);
         }
      }, 1000);
   }

   alignRelatedBoxes(hoverbox) {
      const t = this;

      // console.log('alignRelatedBoxes');

      var tighten = $(hoverbox).hasClass('open') ? true : false;
      // -----------------------------------------------------------------------------
      const relatedBoxes = this.relatedBoxes;
      const totalRelatedHeight = this.totalRelatedHeight;

      clearTimeout(window.alignTimeout);
      window.alignTimeout = setTimeout(function(){
         $('.footer').removeClass('in');
         // console.log(relatedBoxes, totalRelatedHeight, hoverbox);
         // Adjust positions based on the number of related boxes found
         var hoverboxTop = parseFloat($(hoverbox).css('top'));
         var hoverboxLeft = parseFloat($(hoverbox).css('left')) + parseFloat($(hoverbox).css('margin-left'))+ parseFloat($(hoverbox).css('margin-right'));
         
         var hoverboxHeight = $(hoverbox).outerHeight(true);
         var hoverboxWidth = $(hoverbox).outerWidth(true);
         var currentTop = 0;

         if (relatedBoxes.length === 1) {
            // Center the single box vertically relative to the hoverbox
            currentTop = hoverboxTop + (hoverboxHeight / 2) - ($(relatedBoxes[0]).outerHeight() / 2);
         } else {
            // Assume totalRelatedHeight is calculated somewhere in your script as the total height of all related boxes
            // Center the stack of boxes vertically relative to the hoverbox
            if(tighten==true) {
               currentTop = (hoverboxTop - (window.innerHeight-hoverboxHeight)*.5); // - (hoverboxHeight/2);
            } else {
               currentTop = (hoverboxTop - (window.innerHeight-hoverboxHeight)*.5); // + (hoverboxHeight/2);
            }
         }
         
         // sort relatedBoxes by strength
         if(relatedBoxes.length>0){
            // relatedBoxes.sort(function(a, b) {
            //    return $(b).data('strength') - $(a).data('strength');
            // });
            
            // Position each related box
            relatedBoxes.forEach(function(box, idx) {
               // Store original position if not already done
               // $(box).not('[data-origpos]').attr('data-origpos', $(box).css('top') + ' ' + $(box).css('left'));

               // let strengthBonus = (100 * (parseInt(box.dataset.strength)/5));
               
               // Calculate left position based on hoverbox position and width
               // left += strengthBonus; // 5 because its max

               
               let left = 0;
               let boxTop = currentTop + (30 * idx);
               boxTop -= parseInt($(box).css('margin-top'));

               if(tighten==true) {
                  left = hoverboxLeft + hoverboxWidth + Math.random()*20 + 30;
                  left += (5 - parseInt($(box).data('strength'))) * 50;
               }
               if(tighten==false) {
                  left = hoverboxLeft + $(hoverbox).outerWidth(false) + 30;
                  left += (10 * ($('.hovering').length + 1));
                  left += (5 - parseInt($(box).data('strength'))) * 100;
               }
               
               if(!$(box).hasClass('open')) {
                  $(box).addClass('related related-fly');

                  box.dataset.startX = hoverboxLeft + hoverboxWidth; // - parseInt($(box).css('margin-right')); //parseInt($(box).css('left')) + $(box).outerWidth(); // - parseInt($(box).css('margin-right'));
                  box.dataset.startY = parseInt($(box).css('top')) + ($(box).outerHeight(true) / 2) + (10 * idx);
                  
                  box.dataset.endX = hoverboxLeft + hoverboxWidth + $(box).outerWidth(true) / 2; // + $(box).outerWidth(true) / 2; // + parseInt($(box).css('margin-left'));
                  box.dataset.endY = boxTop + ($(box).outerHeight(true) / 2); // + parseInt($(box).css('margin-top'));

                  $(box).css({
                     'top': boxTop + 'px',
                     'left': tighten ? left : left + 'px', // Position to the right of the hoverbox
                     'visibility': 'visible', // Make the box visible
                  });
               }

               currentTop += $(box).outerHeight(false);
               
               // Update currentTop for the next box in the stack, including spacing between boxes
               
            });
            
            
            if(window.miniMap) window.miniMap.update();

            clearTimeout(window.minimapTimeout);
            window.minimapTimeout = setTimeout(function(){
               if(window.miniMap) window.miniMap.update();
            }, 1000);

            $(window).trigger('resize');
            // });
            // t.drawLine();

            /*
            for(var i=0; i < 50; i++) {
               setTimeout(() => { 
                  t.drawLine() 
               }, i*20);
            }
            */
         }
      }, 100);
   }
}