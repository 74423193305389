function generatePastelColor() {
   // Generate a pastel color by limiting RGB values to a high range (200-255) and averaging it with white
   const r = Math.floor((Math.random() * 127) + 127); // Red component (127-255)
   const g = Math.floor((Math.random() * 127) + 127); // Green component (127-255)
   const b = Math.floor((Math.random() * 127) + 127); // Blue component (127-255)
 
   return `rgb(${r}, ${g}, ${b})`;
 }
 
 function generateUniquePastelColors(n) {
   const uniqueColors = new Set(); // Use Set to store unique colors
   
   while (uniqueColors.size < n && uniqueColors.size < 20) {
     uniqueColors.add(generatePastelColor());
   }
   
   return Array.from(uniqueColors); // Convert Set to Array
 }
 

function getPosition(el, name, idx, len) {
   var left = parseInt($(el).css('left')) + $(el).outerWidth(true) - parseInt($(el).css('margin-right')); // - 5;
   if(name=='right') {
      left = parseInt($(el).css('left')) + parseInt($(el).css('margin-left'));
   }
   var top = parseInt($(el).css('top')) + ($(el).outerHeight(false) / 2) + parseInt($(el).css('margin-top'));
   if(name=='left') {
      top -= 10 * Math.floor(len/2);
      top += (10 * idx);
   }
   // console.log(left, top, name);
   return {
      x: left,
      y: top,
   };
}

function calculateQuadraticCurveLength(p0, p1, p2, subdivisions = 100) {
   let length = 0;
   let prevPoint = p0;
   
   // Subdivide the curve into smaller segments
   for (let i = 1; i <= subdivisions; i++) {
      const t = i / subdivisions; // t goes from 0 to 1
      const currentPoint = getQuadraticBezierPoint(t, p0, p1, p2);
      
      // Calculate the distance between the previous point and the current point
      const segmentLength = Math.hypot(currentPoint.x - prevPoint.x, currentPoint.y - prevPoint.y);
      length += segmentLength;
      
      prevPoint = currentPoint;
   }
   
   return length;
}

function getQuadraticBezierPoint(t, p0, p1, p2) {
   // Quadratic Bézier formula to get the point at parameter t
   const x = Math.pow(1 - t, 2) * p0.x + 2 * (1 - t) * t * p1.x + Math.pow(t, 2) * p2.x;
   const y = Math.pow(1 - t, 2) * p0.y + 2 * (1 - t) * t * p1.y + Math.pow(t, 2) * p2.y;
   return { x, y };
}


// Helper function for the cubic Bézier curve length
function calculateCubicCurveLength(p0, p1, p2, p3, subdivisions = 100) {
   let length = 0;
   let prevPoint = p0;
   
   // Subdivide the curve into smaller segments
   for (let i = 1; i <= subdivisions; i++) {
      const t = i / subdivisions; // t goes from 0 to 1
      const currentPoint = getCubicBezierPoint(t, p0, p1, p2, p3);
      
      // Calculate the distance between the previous point and the current point
      const segmentLength = Math.hypot(currentPoint.x - prevPoint.x, currentPoint.y - prevPoint.y);
      length += segmentLength;
      
      prevPoint = currentPoint;
   }
   
   return length;
}

function getCubicBezierPoint(t, p0, p1, p2, p3) {
   // Cubic Bézier formula to get the point at parameter t
   const x = Math.pow(1 - t, 3) * p0.x
   + 3 * Math.pow(1 - t, 2) * t * p1.x
   + 3 * (1 - t) * Math.pow(t, 2) * p2.x
   + Math.pow(t, 3) * p3.x;
   
   const y = Math.pow(1 - t, 3) * p0.y
   + 3 * Math.pow(1 - t, 2) * t * p1.y
   + 3 * (1 - t) * Math.pow(t, 2) * p2.y
   + Math.pow(t, 3) * p3.y;
   
   return { x, y };
}

// this.getPosition = this.getPosition.bind(this);
// this.calculateQuadraticCurveLength = this.calculateQuadraticCurveLength.bind(this);
// this.getQuadraticBezierPoint = this.getQuadraticBezierPoint.bind(this);
// this.calculateCubicCurveLength = this.calculateCubicCurveLength.bind(this);
// this.getCubicBezierPoint = this.getCubicBezierPoint.bind(this);

export default function drawAllLines(canvas, ctx){
   // if(!window.relators.length) return;
   if(!canvas) return;
   if(!ctx) return;
   if(window.relators.length < 1) return;
   // if(!window.mainView.allowRender) return;

   // console.log('drawing');

   ctx.clearRect(0, 0, canvas.width, canvas.height);
   // canvas.style.opacity = .99;
   // draw black rectangle
   // ctx.fillStyle = 'black';
   // ctx.fillRect(0, 0, canvas.width, canvas.height);

   // Define when to start drawing the line (e.g., 50% through the object's movement)
   const delayPercentage = 0.0;

   // window.logged = false;

   for (let i = 0; i < window.relators.length; i++) {
      const leftBlock = window.relators[i]._box;
      if(window.relators[i].relatedBoxes) {
         
         const pastelColors = generateUniquePastelColors(50);
         window.relators[i].relatedBoxes.forEach((block, idx) => {
            if(idx>pastelColors.length-1) idx = Math.floor(Math.random() * (pastelColors.length-1));
            if(!block.uniqueColor) block.uniqueColor = pastelColors[idx];
         });
         
         window.relators[i].relatedBoxes.forEach(function (block, idx) {
            const pointA = getPosition(leftBlock,'left', idx, window.relators[i].relatedBoxes.length); // Starting point
            
            const rightBlock = block;
            // const pointB = getPosition(rightBlock,'right'); // Ending point
            const targetPointB = {
               x: parseFloat(block.dataset.endX), // Target X from dataset
               y: parseFloat(block.dataset.endY)  // Target Y from dataset
            };
            
            const startPointB = {
               x: parseFloat(block.dataset.startX),  // Start X from dataset
               y: parseFloat(block.dataset.startY)   // Start Y from dataset
            };
            
            // Current position of pointB (block's current location)
            const pointB = getPosition(rightBlock,'right', i);
            
            // Calculate total Manhattan distance between startPointB and targetPointB
            const totalDistance = Math.abs(targetPointB.x - startPointB.x) + Math.abs(targetPointB.y - startPointB.y);
            
            // Calculate current Manhattan distance from startPointB to pointB
            const currentDistance = Math.abs(pointB.x - startPointB.x) + Math.abs(pointB.y - startPointB.y) * 1.1;
            
            // Calculate the percentage of distance traveled
            const travelPercentage = currentDistance / totalDistance || 0;
            
            
            // Draw the quadratic curve
            if (travelPercentage > delayPercentage) {
               // Calculate the adjusted travel percentage after the delay
               const adjustedTravelPercentage = (travelPercentage - delayPercentage) / (1 - delayPercentage);
               
               const controlPoint1 = { 
                  x: pointB.x, 
                  y: pointA.y 
               }; // First control point
               const controlPoint2 = { 
                  x: pointA.x, 
                  y: pointB.y 
               }; // Second control point
               const curveLength = calculateCubicCurveLength(pointA, controlPoint1, controlPoint2, pointB);
               
               // Exponential dashOffset calculation for acceleration towards the end
               const n = 1; // Higher values will make it more "exponential"
               const dashOffset = 0;
               // const dashOffset = Math.max(0, curveLength * (1 - Math.pow(adjustedTravelPercentage, n)));
               
               // console.log(controlPoint1.x, controlPoint1.y, controlPoint2.x, controlPoint2.y, pointB.x, pointB.y);
               // console.log(curveLength, dashOffset);

               // Draw cubic Bézier curve with animated dash offset
               ctx.beginPath();
               ctx.moveTo(pointA.x, pointA.y);
               ctx.bezierCurveTo(controlPoint1.x, controlPoint1.y, controlPoint2.x, controlPoint2.y, pointB.x, pointB.y);
               ctx.strokeStyle = block.uniqueColor;
               // ctx.strokeStyle = 'white';
               ctx.lineWidth = 2;
               ctx.stroke();

               // ctx.lineWidth = block.dataset.strength;
               /*
               too ambitious
               if(window.miniMap && window.miniMap.ctx) {
                  let mm = window.miniMap;
                  mm.ctx.beginPath();
                  mm.ctx.moveTo(
                     pointA.x * mm.scale + mm.offset.left,
                     pointA.y * mm.scale + mm.offset.top
                  );
                  mm.ctx.bezierCurveTo(
                     controlPoint1.x * mm.scale + mm.offset.left,
                     controlPoint1.y * mm.scale + mm.offset.top,
                     controlPoint2.x * mm.scale + mm.offset.left,
                     controlPoint2.y * mm.scale + mm.offset.top,
                     pointB.x * mm.scale + mm.offset.left,
                     pointB.y * mm.scale + mm.offset.top
                  );
                  mm.ctx.strokeStyle = block.uniqueColor;
                  // mm.ctx.strokeStyle = 'white';
                  mm.ctx.lineWidth = 3;
                  mm.ctx.stroke();
               }
               */
               // Set dashing properties and adjust dash offset based on adjusted travel percentage
               // ctx.setLineDash([curveLength, curveLength]); // Full dash length equals curve length
               // ctx.lineDashOffset = dashOffset; // Set exponential dash offset
            }
         });
      } // endif relatedBoxes
   }
   // canvas.style.opacity = 1;
}