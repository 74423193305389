export default class toolTip {
   constructor() {
      this.init = this.init.bind(this);
      this.binds = this.binds.bind(this);
      this.update = this.update.bind(this);

      this.usedTips = [];

      this.el = null;
      this.init();
   }

   init() {
      console.log('ToolTip class initialized');
      this.el = document.querySelector('.tooltip');
      this.binds();
   }

   binds() {
      const t = this;
      
      $(document).on('mousemove', function(e) {
         clearTimeout(window.tooltipRaf);
         window.tooltipRaf = window.requestAnimationFrame(function(){
            t.update(e);
         });
      });

      $('[data-tooltip]').on('mouseenter', function(e) {
         if(window.mainView.state.mousedown===null) return;
         const el = this;
         if(t.usedTips.indexOf($(el).data('tooltip')) > -1) return;
         clearTimeout(t.timeout);
         this.timeout = setTimeout(() => {
            t.el.classList.add('active');
            t.el.innerHTML = $(el).data('tooltip');
            t.usedTips.push($(el).data('tooltip'));
         }, 500);
      }).on('mouseleave', function(){
         clearTimeout(t.timeout);
         t.el.classList.remove('active');
      });

      $(document).on('mousedown', function() {
         clearTimeout(t.timeout);
         t.el.classList.remove('active');
      });
   }

   update(e) {
      const t = this;
      t.el.style.transform = `translate3d(calc(${e.pageX}px - 50%), ${e.pageY + 24}px, 0)`;
   }
}