import html2pdf from 'html2pdf.js';
window.html2pdf = html2pdf;
import { jsPDF } from "jspdf";
window.jsPDF = jsPDF;

const toDataUrl = async function (url, callback) {
   //Convert to base64
   return new Promise((resolve, reject) => {
     var xhr = new XMLHttpRequest();
     xhr.onload = function () {
       var reader = new FileReader();
       reader.onloadend = function () {
         resolve(reader.result);
       };
       reader.readAsDataURL(xhr.response);
     };
     xhr.onerror = () => {
       reject({
         // status: this.status,
         statusText: xhr.statusText,
       });
     };
     xhr.open("GET", url);
     xhr.responseType = "blob";
     xhr.send();
   });
 };

 const canvasDataUrl = async function (el) {
   const img = el.get(0);
   img.crossorigin = "anonymous";
   console.log(img);
   const canvas = document.createElement('canvas');
   const ctx = canvas.getContext('2d');

   // Set canvas dimensions to match image
   canvas.width = img.width;
   canvas.height = img.height;

   // Draw the image on the canvas
   ctx.drawImage(img, 0, 0);

   // Convert the canvas to a Data URL (can also use Blob)
   // const dataURL = canvas.toDataURL('image/jpeg');  // Use 'image/jpeg' for JPEG format


   // Optionally, if you want to replace the original image with the captured dataURL
   // img.src = dataURL;

   // Or, you can convert the canvas content to a Blob
   canvas.toBlob(function(blob) {
      console.log(blob);
     return blob;
   }, 'image/png');  // Specify desired format, e.g., 'image/png' or 'image/jpeg'
 };


export default class Log {
   constructor() {
      window.log = this;
      this.data = [];
      console.log('Log class initialized');
      this.update = this.update.bind(this);
      this.download = this.download.bind(this);

      $('.log--download').on('click', this.download);

      $('.log-wrap').off('mousemove').on('mousemove', function(e){
         let x = e.clientX - 140;
         let w = $(this).width() - 140;
         let p = x / w;
         let scroll = $(this).scrollLeft();
         let max = $(this).get(0).scrollWidth - $(this).width();
         let to = p * max;
         $(this).scrollLeft(to);
      });
   }

   add(el) {
      $('.log').addClass('in');

      let count=1;

      let idx = this.data.length + 1;

      // if(this.data.length > 0) { 
      //    // console.log(this.data);
      //    // let localCount = 0;
      //    // let localIdx = 0;
      //    this.data.forEach(d => {
      //       if(d._slug === el._slug) {
      //          // idx = $(`.log--item[data-id="${el._slug}"]`).attr('data-idx');
      //          let localIdx = $(`.log--item[data-id="${el._slug}"]`).attr('data-idx');
      //          idx = localIdx;
      //          this.data.splice(localIdx, 1);
      //          $(`.log--item[data-id="${el._slug}"]`).remove();
      //          // $(`.log--item[data-idx="${idx}"]`).remove();
      //          // localCount++;
      //          // $(this).attr('data-count', count);
      //       }
      //       // localIdx++;
      //    });
      // }
      if(this.data.indexOf(el) > -1) {
         // idx = $(`.log--item[data-id="${el._slug}"]`).attr('data-idx');
         // $(`.log--item[data-id="${el._slug}"]`).remove();
      } else {
         this.data.push(el);
         
         let newItem = $(`<div class="log--item" data-count="${count}" data-idx="${idx}" data-id="${el._slug}"><span>${el.title}</span><em></em></div>`);
         newItem.hide();
         $('.log--content').prepend(newItem);
         $('.log-wrap').animate({ scrollLeft: 0 }, 500);
         newItem.show(1000);
         
         $(newItem).children('span').on('click', function(){
            // let id = this.dataset.id;
            if($(el._box).hasClass('open')) {
               return;
            } else {
               if($('.box.open').length > 0) {
                  $('.box-backdrop').click();
               }
               setTimeout(function(){
                  $(el._box).trigger('click');
               }, 1);
            }
         });
         
         $(newItem).children('em').on('click', function(){
            let id = this.parentNode.dataset.id;
            let idx = this.parentNode.dataset.idx;
            this.parentNode.remove();
            log.data.splice(idx, 1);
            $(`.box[data-id="${id}"]`).remove();
            log.update();        
         });
      }

   }

   download() {
      // $('.loading').fadeIn(400);
      $('body').addClass('blur');

      if(!window.pdfCreating) {
         window.pdfCreating = true;
         
         setTimeout(async () => {
            let clones = [];
            $('.log--item').each(function(){
               let id = this.dataset.id;
               let clone = $(`#${id}`).clone();
               // clone.appendTo('.log--pages');
               clone.removeClass('related related-fly hovering').addClass('open');
               // no need to getReferences since the box has been opened at least once
               clone.attr('style','');
               clone.find('.box-content').show();
               clone.find('.close').remove();
               clone.find('.title').text(`#${this.dataset.idx} ${clone.find('.title').text()}`);
               $(clone).attr('data-idx', this.dataset.idx);
               // clone.get(0).outerHTML = clone.get(0).outerHTML.replaceAll('<article>','<div>').replaceAll('</article>','</div>');
               
               var newDiv = $('<div />', {
                  html: $(clone).html(), // Copy the inner content
                  class: $(clone).attr('class'), // Copy the class
               });
               
               $(clone).replaceWith(newDiv);
               
               clones.push(clone);
            });
            // let items = $('.log--pages .box');
            let sorted = clones.sort(function(a, b){
               return $(a).data('idx') - $(b).data('idx');
            });
            const pdfContainer = document.createElement('div');
            pdfContainer.className = 'log--pages';
            pdfContainer.style.width = '800px';
            // const boxes = $('.log--pages .box.open');
            $(sorted).each(function() {
               const clone = this.get(0).cloneNode(true); // Clone each .box.open
               pdfContainer.appendChild(clone);    // Add cloned box to the container
            });
            let d = new Date();
            let timestamp = d.toLocaleDateString()+'-'+d.toLocaleTimeString();
            timestamp = timestamp.replace(/[^0-9]/g, '');
            
            let filename = `hyperimage-atlas-log-${timestamp}.pdf`;

            // let imagesUrls = [
            //    "https://img-19.commentcamarche.net/cI8qqj-finfDcmx6jMK6Vr-krEw=/1500x/smart/b829396acc244fd484c5ddcdcb2b08f3/ccmcms-commentcamarche/20494859.jpg",
            //    "https://img-19.commentcamarche.net/cI8qqj-finfDcmx6jMK6Vr-krEw=/1500x/smart/b829396acc244fd484c5ddcdcb2b08f3/ccmcms-commentcamarche/20494859.jpg",
            //    "https://img-19.commentcamarche.net/cI8qqj-finfDcmx6jMK6Vr-krEw=/1500x/smart/b829396acc244fd484c5ddcdcb2b08f3/ccmcms-commentcamarche/20494859.jpg",
            // ];
            var imagesUrls = $(pdfContainer).find('img').map(function() {
               return $(this).attr('src');
            }).get();
            for (let url of imagesUrls) {

               try {
                  // const image = await fetch(url);
                  // const imageBlob = await image.blob();
                  // const blobUrl = URL.createObjectURL(imageBlob);
                  // $(pdfContainer).find('img[src="' + url + '"]').attr('src', blobUrl);
                  // imagesArray[i].image = blobUrl;
                  // console.log('awaiting dataurl');
                  // let urlBase64 = await toDataUrl(url);
                  let img = $(pdfContainer).find('img[src="' + url + '"]');
                  let urlBase64 = await canvasDataUrl(img);
                  $(pdfContainer).find('img[src="' + url + '"]').attr('src', urlBase64);
                  // console.log('dataurl received', urlBase64);
               } catch (error) {
                  $(pdfContainer).find('img[src="' + url + '"]').hide();
               }
               // content += ´'<img src="' + urlBase64 + '" width="600" ><br>';
             }
       
            
            html2pdf()
            .set({
               margin: 0,
               filename: filename,
               html2canvas: {
                  scale: 2,
                  useCORS: true
               },
               image: { type: 'jpeg', quality: 0.90 },
               pagebreak: { after: ['.box:not(:last-of-type)'], avoid: 'img' },
               jsPDF: { 
                  unit: 'in',
                  format: 'a4',
                  orientation: 'portrait'
               } // PDF settings
            })
            .from(pdfContainer)
            .save()
            .then(() => {
               window.pdfContainer = pdfContainer;
               pdfContainer.remove();
               // This block is executed when the PDF generation is finished
               console.log('PDF generation complete');
               setTimeout(() => {
                  window.pdfCreating = false;
                  // $('.loading').fadeOut(400);
                  $('body').removeClass('printing blur');
               }, 200);
               // You can execute any logic here after the PDF is created
            })
            .catch((error) => {
               window.pdfContainer = pdfContainer;
               pdfContainer.remove();
               // This block is executed if there's an error during the process
               console.error('PDF generation failed', error);
               setTimeout(() => {
                  window.pdfCreating = false;
                  // $('.loading').fadeOut(400);
                  $('body').removeClass('printing blur');
               }, 200);
            });
         }, 100);
      }
   }

   update() {
      if($('.log--item').length < 1) {
         $('.log').removeClass('in');
      } else {
         $('.log').addClass('in');
      }
      // $('.log--content').html('');
      // this.data.forEach((l, idx) => {
      //    let name = l.title;
      //    let newItem = $(`<div class="log--item" data-idx="${idx}">${name}</div>`);
      //    newItem.hide();
      //    $('.log--content').append(newItem);
      //    newItem.show(500);
      // });
   }
}