global.Mustache = require('mustache');
global.$ = require('jquery');
global.isotope = require('isotope-layout');
global.packery = require('isotope-packery');

import { io } from "socket.io-client";

global.socket = io(window.location.origin, {
   secure: true,
   // secure: window.location.protocol.indexOf('https')>-1,
   transports: [ "websocket", "polling" ]
});

import {isValidIsbn, isValidDoi, isValidUrl} from './modules/is-valid-isbn-doi';
global.isValidIsbn = isValidIsbn;
global.isValidDoi = isValidDoi;
global.isValidUrl = isValidUrl;

// Modules
import Entries from "./modules/entries";
import Ux from "./modules/ux";
import Log from "./modules/log";
import About from "./modules/about";

var ts = localStorage.getItem('hyperimageatlas-pages-timestamp');
var timestampNotTooOld = ts && parseInt(ts) + 720000 > Date.now();

function launch() {
   if($('body').hasClass('home')) {
      $(function(){
         window.ux = new Ux();

         if(!window.lineAnimate) {
            window.lineAnimate = requestAnimationFrame(window.mainView.animate);
            $(window).trigger('resize');
         }
         
         $('html').scrollLeft(0);
      });
   }
   if($('.about-text').length) {
      window.about = new About();
   }
   
   if($('body').hasClass('about')) {
      $('body').removeClass('blur');
      window.about = new About();
   }

   if($('body').hasClass('bibliography')) {
      $('body').removeClass('blur');
   }

   // console.log('Done!');
   setTimeout(() => {
      $('.loading--state').text('Done');
      clearInterval(window.loadInterval);
      $('.loading i').text('');

      let loadingStateRaf = requestAnimationFrame(function(){
         clearInterval(window.loadInterval);
      });

      setTimeout(() => {
         $('.loading--state').text('Loading');
      }, 2000);
   }, 100);
}

if(localStorage.getItem('hyperimageatlas-pages') && timestampNotTooOld) {
   $('.loading--state').text('Creating entries');
   setTimeout(function(){
      console.log('localStorage Entries, timestamp valid for:', (parseInt(ts) + 120000) - Date.now()); // 20 min
      // console.log('localStorage OK, timestamp valid for:', (parseInt(ts) + 720000) - Date.now());
      window.pages = JSON.parse(localStorage.getItem('hyperimageatlas-pages'));
      // console.log('Using pages from localStorage:', pages);
      window.entries = new Entries(pages);
      setTimeout(function(){
         launch();
      }, 50);
   }, 1000);
} else {
   $('.loading--state').text('Fetching data');
   console.log('Fetching data...');
   socket.on('receivePages', (pages) => {
      clearTimeout(window.impatient);
      $('.loading--state').text('Creating entries');
      setTimeout(function(){
         console.log('Received pages:', pages);
         localStorage.setItem('hyperimageatlas-pages-timestamp', Date.now());
         localStorage.setItem('hyperimageatlas-pages', JSON.stringify(pages));
         window.pages = pages;
         window.entries = new Entries(pages);
         requestAnimationFrame(function(){
            launch();
         });
      }, 50);
   });
   setTimeout(function(){
      socket.emit('getRows');

      window.impatient = setTimeout(function(){
         window.location.href = window.location.href;
      }, 20000);
   }, 50);
}

socket.on('addWorksheet', (ws) => {
   console.log(ws);
});

if($('body').hasClass('home')) {
   new Log();
   
   let haveGuideInLocal = localStorage.getItem('hyperimageatlas-guide');
   let guideHasPositive = haveGuideInLocal && haveGuideInLocal.indexOf('true')>-1;
   let guideClosed = (!haveGuideInLocal || guideHasPositive) || false;
   if(guideClosed == false) {
      $('.guide').addClass('show');
   }

   window.loadDots = 0;
   window.loadInterval = setInterval(function(){
      window.loadDots++;
      var t = '.';
      for(var i=0; i<window.loadDots;i++){
         t += '.';
      }
      if(window.loadDots > 3){
         window.loadDots = 0;
         t = '.';
      }
      $('.loading i').text(t);
   }, 200);
}
