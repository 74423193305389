export default class bibliography {
   constructor() {
      this.bibs = [];
      this.removeIsbnDois = [];
      this.getReferenceOpenBox = this.getReferenceOpenBox.bind(this);
      this.getReferences = this.getReferences.bind(this);
      this.fetchDOIBatch = this.fetchDOIBatch.bind(this);
      this.fetchISBNBatch = this.fetchISBNBatch.bind(this);
      this.genericBinds = this.genericBinds.bind(this);
      this.bibliographyBinds = this.bibliographyBinds.bind(this);
      this.checkForLocal = this.checkForLocal.bind(this);
      this.saveBibs = this.saveBibs.bind(this);
      this.findRel = this.findRel.bind(this);
      this.bindSingle = this.bindSingle.bind(this);
      
      this.genericBinds();

      this.checkForLocal();
   }

   checkForLocal() {
      const t = this;
      var ts = localStorage.getItem('hyperimageatlas-bibs-timestamp');
      var timestampNotTooOld = ts && parseInt(ts) + 720000 > Date.now();

      // If local storage exists and is not too old, use it
      if(localStorage.getItem('hyperimageatlas-bibs') && timestampNotTooOld) {
         t.bibs = JSON.parse(localStorage.getItem('hyperimageatlas-bibs'));
         t.disableSave = true;
         t.processBibs();

         setTimeout(function(){
            console.log('localstorage delayed call for getreferences');
            t.getReferences();
         }, 8000);
      } else {
         // If no local storage, fetch the data
         t.getReferences();
      }
   }

   findRel(bib) {
      const t = this;

      // find by filtering legitEntries id
      let found = window.entries.legitEntries.filter((entry) => { entry.id == bib.id });

      window.entries.legitEntries.forEach((entry) => {
         // if(entry.id == bib.id ||
         if (bib.id && entry.id) {
            let parentId = bib.id.split('_')[0];
            if(parseInt(parentId) == parseInt(entry.id)) {
               // console.log(entry.id);
               bib.rel = entry;
               // bib.el.dataset.id = entry.id;
               // console.log('Chose rel', bib.rel);
            }
         } else if ((entry.isbn && bib.isbn && entry.isbn == bib.isbn) || (entry.doi && bib.doi && entry.doi == bib.doi) ) {
            if(!bib.id){ 
               console.log('No bib id, dumb', bib);
            }
            bib.rel = entry;
            console.log('Rechose rel', bib.rel);
         }
      });
   }

   processBibs() {
      console.log('%cProcessing bibs', 'color: #ff0; font-size: 1em;');
      const t = this;
      t.bibs.filter(a => !a.done).forEach((bib) => {
         // if(bib.done) return;
         if(!bib.isError) {
            var fullString = '';
            let data = bib;
            if(bib.isISBN) {
               // ISBN ------------------------------
               let names = '';
               // console.log(bib);
               if(data.authors!==undefined && data.authors.length > 0) {
                  for (var i = 0; i < data.authors.length; i++) {
                     if (data.authors[i].name.split(' ').length >= 3) {
                        names += data.authors[i].name;
                     } else {
                        names += data.authors[i].name.split(' ')[data.authors[i].name.split(' ').length - 1] + ', ' + data.authors[i].name.split(' ')[0];
                     }
                     if (i === data.authors.length - 2) {
                        names += ', and ';
                     } else if (data.authors.length > 2 && i < data.authors.length - 1) {
                        names += ', ';
                     }
                  }
                  if(names!='') names += '. ';
               }
               let pubs = data.publishers ? data.publishers[0].name+', ' : '';
               let dt = data.publish_date ? data.publish_date+'. ':'';
               fullString = `<p>${names}<em>${data.title}</em>. ${pubs}${dt}<a href="${data.url}" onclick="window.open('${data.url}')">. ISBN: ${data.isbn}</a>.</p>`;
               bib.names = names;
               bib.year = data.publish_date;
               if(data.publish_date && data.publish_date.split(',').length > 1) bib.year = data.publish_date.split(',')[1].trim();
               else if(data.publish_date && data.publish_date.split(' ').length > 1) bib.year = data.publish_date.split(' ')[1].trim();
               bib.title = data.title;
               bib.id = data.id;
               t.findRel(bib);
            } else if(bib.isDOI) {
               // DOI ------------------------------
               let names = '';
               if (data.author) {
                  for (var i = 0; i < data.author.length; i++) {
                     names += data.author[i].family + ', ' + data.author[i].given;
                     if (i === data.author.length - 2) {
                        names += ', and ';
                     } else if (data.author.length > 2 && i < data.author.length - 1) {
                        names += ', ';
                     }
                  }
               }
               let dateparts = data.issued && data.issued['date-parts'] ? data.issued['date-parts'][0] : null;
               let date = dateparts ? `${dateparts[2]}.${dateparts[1]}.${dateparts[0]}` : '';
               fullString = `<p>${names}. "${data.title}." ${data['short-container-title']}, ${data.volume ? data.volume+', ' : ''} ${data.issue? data.issue+', ':'' } ${date}, pp. ${data.page}. ${data.publisher}, DOI: <a href="${data.URL}" onclick="window.open('${data.URL}')">${data.DOI}</a>.</p>`;
               bib.names = names;
               bib.year = dateparts ? dateparts[0] : data.issued;
               bib.title = data.title;
               bib.id = data.id;
               t.findRel(bib);
            } else {
               // console.log(`%cEXCLUSION:`, 'color: #f00;', bib);
               // console.log(bib);
            }

            // Update the corresponding element based on ISBN
            $(`.box [data-doi='${bib.doi}'],
               .box [data-isbn='${bib.isbn}']`).each(function(){
                  // console.log(fullString);
                  $(this).html(fullString)
                     .attr('data-id', bib.id+'_'+$(this).index())
                     .removeClass('load')
                     .removeClass('rm')
                     .removeAttr('data-fetchurl onclick href')
                     .off('click');
            });
            
            // Use bib.year, bib.title and bib.names to create div with data-id attributes
            // let bibHtml = '';
            // bibHtml += 
            if($(`.bib--content [data-title="${bib.title}"]`).length == 0) {
               let bibHtml = $(`<div 
               class="bib--content--entry"
               data-year="${bib.year}"
               data-title="${bib.title}"
               data-names="${bib.names}">
               <div class="id">${bib.id}</div>
                  <div class="year">${bib.year}</div>
                  <div class="title">${bib.title}</div>
                  <div class="names">${bib.names}</div>
                  </div>`);

               $('.bib--content').append(bibHtml);
               bib.el = bibHtml.get(0);
            } else {
               let ids = $(`.bib--content [data-title="${bib.title}"] .id`).text();
               ids += '] ['+bib.id;
               $(`.bib--content [data-title="${bib.title}"] .id`).text(ids);
            }
            bib.done = true;
         } else { 
            // if IS bib.isError ------------------------------
            t.findRel(bib);
            // console.log('is error, finding rel', bib.rel);

            let matches = $(`.box [data-doi='${bib.doi}'], .box [data-isbn='${bib.isbn}']`);
            if(matches.length>0) {
               $(matches).each(function(){
                  $(this).attr('data-id', 'errored').removeClass('load'); //.addClass('rm');
                  // console.log('%cError fetching data for:', 'color: #f00;', bib);
                  if($(this).is(':only-child')) {
                     // $(this).parents('.references').addClass('rm');
                  } else {
                     var par = $(this).parents('.references');
                     if(par.children().length == par.children('.rm').length) {
                        // par.addClass('rm');
                     }
                  }
               });
            } else {
               // console.log('NO MATCHES', bib);
            }
            bib.done = true;
         }
      });

      $('.bib-link').removeClass('disabled');
      
      setTimeout(function(){
         t.bibliographyBinds();
         t.saveBibs();
      }, 200);
   }

   saveBibs() {
      if(this.disableSave) return;
      localStorage.setItem('hyperimageatlas-bibs-timestamp', Date.now());
      localStorage.setItem('hyperimageatlas-bibs', JSON.stringify(this.bibs));
   }

   bindSingle(bib) {
      /*
      if(bib.rel) {
            $(bib.el).off('mouseenter click').on('mouseenter', function(){
               bib.rel.centerBox(bib.rel._box.get(0))
            }).on('click', function(){
               window.preventClick = true;
               setTimeout(function(){
                  $('body').removeClass('bib-open');
                  $(bib.rel._box).trigger('click');
               }, 10);
               setTimeout(function(){
                  window.preventClick = false;
               }, 400);
            });
         }
      */
   }

   genericBinds() {
      $('.bib-link').on('click', function(e){
         if(!e.metaKey && !e.ctrlKey && !e.shiftKey) {
            e.preventDefault();
            $('body').removeClass('about-open');
            $('body').toggleClass('bib-open');
         }
      });

      $('.bib--close').on('click', function(e){
         e.preventDefault();
         $('body').removeClass('bib-open');
      });
   }

   bibliographyBinds() {
      const t = this;
      $('.bib--content a').on('click', function(e){
         if(!e.metaKey && !e.ctrlKey && !e.shiftKey) {
            e.preventDefault();
            window.open($(this).attr('href'));
         }
      });

      t.bibs.forEach(bib => {
         // console.log('bindSingle.el', bib.el);
         if(bib.rel && bib.rel._box) {
            $(bib.el).off('mouseenter click').on('mouseenter', function(){
               try {
                  bib.rel.centerBox(bib.rel._box.get(0))
               } catch(e) {
                  $(bib.el).addClass('no-rel');
                  // console.log(`%c${bib.rel._box} no get`, 'color: #f00;', bib.rel._box);
               }
            }).on('click', function(){
               try {
                  window.preventClick = true;
                  setTimeout(function(){
                     $('body').removeClass('bib-open');
                     $(bib.rel._box).trigger('click');
                  }, 10);
                  setTimeout(function(){
                     window.preventClick = false;
                  }, 400);
               } catch(e) {
                  $(bib.el).addClass('no-rel');
               }
            });
            // bib.binds = true;
         } else {
            $(bib.el).addClass('no-rel');
         }
      });

      this.initIsotope();
   }

   initIsotope() {
      // var $grid = $('.bib--content').isotope({
      // var $grid = $('.bib--content').isotope({
         var iso = new isotope( document.querySelector('.bib--content'), {
         itemSelector: '.bib--content--entry',
         stagger: 0,
         transitionDuration: 0,
         layoutMode: 'vertical',
         getSortData: {
            year: '[data-year]',
            title: '[data-title]',
            names: '[data-names]'
         },
         sortBy: 'title',
         initLayout: true,
      });
      this.iso = iso;

      $('.bib--sort').on('click', function(e){
         e.preventDefault();
         var sortValue = $(this).attr('data-prop');
         if($(this).hasClass('cur')) {
            $(this).toggleClass('asc');
         } else {
            $('.bib--sort').removeClass('cur asc');
            $(this).addClass('cur asc');
         }
         iso.arrange({
            sortBy: sortValue,
            sortAscending: $(this).hasClass('asc')
         });
      });
   }

   getReferenceOpenBox(tar) {
      const t = this;
      let doiList = [];
      let isbnList = [];

      // Collect all DOIs from [data-doi] elements
      $('.box.open [data-doi].load').each(function() {
         const doi = $(this).attr('data-doi');
         doiList.push({
            doi: doi,
            id: this.dataset.id
         }); // Collect DOI
      });

      // Collect all ISBNs from [data-isbn] elements
      $('.box.open [data-isbn].load').each(function() {
         const isbn = $(this).attr('data-isbn');
         isbnList.push({
            isbn: isbn,
            id: this.dataset.id
         }); // Collect ISBN
      });

      if (doiList.length > 0) {
         this.fetchDOIBatch(doiList, 100);
      }
      if (isbnList.length > 0) {
         this.fetchISBNBatch(isbnList, 100);
      }
   }
   
   getReferences() {
      const t = this;
      // console.log('getReferences', t);
      let doiList = [];
      let isbnList = [];


      // Collect all DOIs from [data-doi] elements
      $('[data-doi].load').each(function() {
         const doi = $(this).attr('data-doi');
         doiList.push({
            doi: doi,
            id: this.dataset.id
         }); // Collect DOI
      });

      // Collect all ISBNs from [data-isbn] elements
      $('[data-isbn].load').each(function() {
         const isbn = $(this).attr('data-isbn');
         isbnList.push({
            isbn: isbn,
            id: this.dataset.id
         }); // Collect ISBN
      });
      
      // Submit a request for all DOIs with a delay between each request
      if (doiList.length > 0) {
         this.fetchDOIBatch(doiList, 100); // 100ms delay between requests
      }
      
      // Submit a request for all ISBNs with a delay between each request
      if (isbnList.length > 0) {
         this.fetchISBNBatch(isbnList, 100); // 100ms delay between requests
      }
   }
   
   fetchDOIBatch(doiObjList, delay) {
      const t = this;
      doiObjList.forEach((obj, index) => {
         const doi = obj.doi;
         const id = obj.id;
         // console.log(`loading ${id}`);
         setTimeout(() => {
            let apiUrl = `https://api.crossref.org/works/${doi}`;
            
            fetch(apiUrl)
            .then(response => {
               if (!response.ok) {
                  let errorData = {
                     isError: true,
                     isDOI: true,
                     isISBN: false,
                     doi: doi,
                     id: id
                  };
                  t.bibs.push(errorData);
                  // console.error('Error fetching doi:', response);
                     
                  clearTimeout(window.bibTimeout);
                  window.bibTimeout = setTimeout(function(){
                     // console.log('!response.ok triggered processBibs doi timeout');
                     t.processBibs();
                  }, 2000);
                  throw new Error(`HTTP error! status: ${response.status}`);
               }
               return response.json();
            })
            .then(data => {
               if (data.message) {
                  data.doi = doi;
                  data.message.isDOI = true;
                  data.message.isISBN = false;
                  data.message.id = id;
                  t.bibs.push(data.message);
                  
                  clearTimeout(window.bibTimeout);
                  window.bibTimeout = setTimeout(function(){
                     // console.log('.then(data triggered processBibs doi timeout');
                     t.processBibs();
                  }, 2000);
               } else {
                  // console.log('no data.message', data);
               }
            })
            .catch(error => {
               // let errorData = {
               //    isError: true,
               //    isISBN: false,
               //    doi: doi,
               //    id: id
               // };
               // t.bibs.push(errorData);
               // console.error('Error fetching DOIs:', error);
                  
               //    t.processBibs();
               // }, 2000);
            });
         }, index * delay); // Delay each request by the specified delay (in ms)
      });
   }
   
   fetchISBNBatch(isbnObjList, delay) {
      const t = this;
      // console.log('fetchISBNBatch', isbnObjList);
      isbnObjList.forEach((obj, index) => {
         const isbn = obj.isbn;
         const id = obj.id;
         // console.log(`loading ${id}`);
         // setTimeout(() => {
            let apiUrl = `https://openlibrary.org/api/books?bibkeys=ISBN:${isbn}&format=json&jscmd=data`;
            
            fetch(apiUrl)
            .then(response => {
               if (!response.ok) {
                  let errorData = {
                     isError: true,
                     isISBN: true,
                     isDOI: false,
                     isbn: isbn,
                     id: id
                  };
                  t.bibs.push(errorData);
                  // console.error('Error fetching ISBNs:', response);
                     
                  clearTimeout(window.bibTimeout);
                  window.bibTimeout = setTimeout(function(){
                     // console.log('!response.ok triggered processBibs ISBNs timeout');
                     t.processBibs();
                  }, 2000);

                  // throw new Error(`HTTP error! status: ${response.status}`);
               }
               return response.json();
            })
            .then(data => {
               let entryData = data[`ISBN:${isbn}`];
               if (entryData) {
                  entryData.isISBN = true;
                  entryData.isDOI = false,
                  entryData.isbn = isbn;
                  entryData.id = id;
                  t.bibs.push(entryData);
                  
                  clearTimeout(window.bibTimeout);
                  window.bibTimeout = setTimeout(function(){
                     // console.log('.then(data triggered processBibs ISBN timeout');
                     t.processBibs();
                  }, 2000);
               } else {
                  let entryData = {};
                  entryData.isError = true;
                  entryData.isISBN = true;
                  entryData.isDOI = false,
                  entryData.isbn = isbn;
                  entryData.id = id;
                  t.bibs.push(entryData);
               }
            })
            .catch(error => {
               // let errorData = {
               //    isError: true,
               //    isISBN: true,
               //    isbn: isbn,
               //    id: id
               // };
               // t.bibs.push(errorData);
               // console.error('Error fetching ISBNs:', error);
                  
               //    t.processBibs();
               // }, 2000);
            });
         // }, index * delay); // Delay each request by the specified delay (in ms)
      });
   }
}