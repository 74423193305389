import Entry from "./entry";
import bibliography from "./bibliography";
import miniMap from './miniMap';

export default class Entries {
   constructor(pages) {
      console.log('Constructing Entries');
      $('.loading--state').text('Creating entries');
      var _this = this;
      this.entries = [];
      this.themes = [];
      this.cats = [];
      this.legitEntries = [];
      window.bibsCount = 0;
      window.preventClick = false;
      window.entries = this;

      for(let i = 0; i < pages.length; i++) {
         var headers = pages[i].headers;

         pages[i].rows.forEach(row => {
            _this.entries.push(new Entry(row, headers));

            // if last
            if(i==pages.length-1) {
            }
         });
      }

      _this.entries.forEach((a, idx) => {
         if(a.theme && _this.themes.indexOf(a.theme) < 0) {
            _this.themes.push(a.theme);
         }

         if(a._cat && _this.cats.indexOf(a._cat) < 0) {
            _this.cats.push(a._cat);
         }

         a.id = idx;
         a.createBlock(idx);
      });

      console.log('createBlocks done', _this.entries.length);

      _this.entries.forEach((a, idx) => {
         a.initRelateds();
         // for(var i=0; i<_this.entries.length; i++) {
         //    _this.entries[i].createBlock();
         // }

         if(idx==_this.entries.length-1) {
            setTimeout(function(){
               console.log('initRelateds done', _this.entries.length);
               $('body').addClass('rdy');
               $('.loading').fadeOut(400);

               setTimeout(function(){
                  $('body').addClass('instant');
                  if($('.box#hyperimage').length>=0){
                     let initialFocus = $('.box#hyperimage').get(0);
                     setTimeout(function(){
                        window.entries.legitEntries[0].centerBox(initialFocus);
                        setTimeout(function(){
                           $('body').removeClass('instant');
                           
                           setTimeout(function(){
                              $('body.blur').removeClass('blur');
                              window.miniMap = new miniMap();
                              $(window).trigger('resize');
                           }, 100);
                        }, 200);
                     }, 10);
                  } else {
                     $('body.blur').removeClass('blur');
                     window.miniMap = new miniMap();
                     $(window).trigger('resize');
                  }
               }, 50);

               setTimeout(function(){ 
                  window.bib = new bibliography();
                  // _this.getReferences();
               }, 500);
            }, 1000);
         }
      });
   }
}