import mainView from './mainView';
import toolTip from './toolTip';

export default class Ux {
   constructor() {
      this.activeFilters = {
         cats: ['Concepts', 'Artefacts', 'Artworks'],
         themes: [],
         search: '',
         theme: '',
         onlyTheory: false
      };

      this.guideTexts = {
         drag: document.querySelector('p.drag'),
         mouseover: document.querySelector('p.mouseover'),
         open: document.querySelector('p.open'),
         backdrop: document.querySelector('p.backdrop'),
         backdropdouble: document.querySelector('p.backdropdbl')
      };

      $('nav.cats a').on('click', function(e){
         // e.preventDefault();
         $('nav.cats a.cur').removeClass('cur');
         $(this).addClass('cur');
         var t = $(this).attr('href').substring(1);
         console.log(t);
         $(`.boxes.act`).removeClass('act');
         $(`.boxes.${t}`).addClass('act');
         
         $('main').attr('class','').addClass(`nth-act-${$('.boxes.act').index()+1}`);
      });

      if($(`nav.cats a[href="${window.location.hash}"]`).length>0) {
         $(`nav.cats a[href="${window.location.hash}"]`).trigger('click');
      } else {
         $(`nav.cats a`).first().trigger('click');
      }

      // setTimeout(function(){
      //    $('main').attr('class','').addClass(`nth-act-${$('.boxes.act').index()+1}`);
      // }, 400);

      $('.box-backdrop').on('click', function(e){
         e.preventDefault();
         if(!window.preventClick) {
            if($('.box.open').length>0) {
               $('.box.open .close').click();
               window.ux.guideTexts.backdrop.classList.add('ok');

            } else {
               if(window.relators.length>0) {
                  window.relators[window.relators.length-1].closeRelateds();
                  // window.relators[0].clearRelateds();
               }
            }
         }
      }).on('dblclick', function(e){
         e.preventDefault();
         if(window.relators.length>0) {
            window.relators[0].clearRelateds();
         }
         window.ux.guideTexts.backdropdouble.classList.add('ok');
         $('.footer').addClass('in');
      });

      this.bindRandom();

      this.initSearch();

      this.initFilters();

      this.initIsotope();
    
      this._mapRange = this._mapRange.bind(this);
      
      window.mainView = new mainView($('main').get(0));

      // window.toolTip = new toolTip();
   }

   bindRandom(){
      window.lit = false;

      $('a[href="#lit"]').on('click', function(e){
         e.preventDefault();
         if(!window.preventLit) {
            window.lit = !window.lit;
            $('html').addClass(`lit-anim ${window.lit?'to-white':'to-black'}`);
            setTimeout(function(){
               if(window.lit) {
                  $('html').addClass('lit');
               } else {
                  $('html').removeClass('lit');
               }
               setTimeout(function(){
                  $('html').removeClass('to-white to-black lit-anim');
               }, 100);
            }, 400);
         }
      });

      $('.guide-link').on('click', function(e){
         e.preventDefault();
         $('.guide').toggleClass('show');
         localStorage.setItem('hyperimageatlas-guide-closed', !$('.guide').hasClass('show'));
      });

      $('.guide--close').on('click', function(e){
         e.preventDefault();
         $('.guide').removeClass('show');
      });

      $('.toggle-unimportant').on('click', function(e){
         e.preventDefault();
         $('.box.unimportant').toggleClass('show');
         $(this).toggleClass('on');
      });

      $('.reset').on('click', function(e){
         e.preventDefault();
         let catInputs = $('.cats input');
         window.ux.activeFilters.cats = [];
         for(let i=0; i<catInputs.length;i++) {
            catInputs[i].checked = true;
            window.ux.activeFilters.cats.push(catInputs[i].value);
         }
         let themeInputs = $('.theme-select input');
         window.ux.activeFilters.themes = [];
         for(let i=0; i<themeInputs.length;i++) {
            themeInputs[i].checked = true;
            window.ux.activeFilters.themes.push(themeInputs[i].value);
         }
         $('.search').val('').trigger('change');
         $('.view-as').val('text').trigger('change');
         $('.group-by').val('title').trigger('change');
         $('.theme-select').val('').trigger('change');
         $('.only-theory').prop('checked', false).trigger('change');

         if(window.isotopes) {
            window.isotopes[0].layout();
         }
         if(window.relators.length>0) {
            window.relators[0].clearRelateds();
         }
         
         if($('.box#hyperimage').length>0) {
            clearTimeout(window.centerHyperTimeout);
            window.centerHyperTimeout = setTimeout(function(){
               let initialFocus = $('.box#hyperimage').get(0);
               setTimeout(function(){
                  window.entries.legitEntries[0].centerBox(initialFocus);
               }, 1);

               setTimeout(function(){
                  let initialFocus = $('.box#hyperimage').get(0);
                  setTimeout(function(){
                     window.entries.legitEntries[0].centerBox(initialFocus);
                  }, 1);
               }, 600);
            }, 100);
         } else {
            window.mainView.state.scale = 0.4;
            window.mainView.state.translateX = 0;
            window.mainView.state.translateY = 0;
            window.mainView.updateTransform();
         }
      });
   } 
   
   applyFilters() {
      clearTimeout(window.filterDebounce);
      window.filterDebounce = setTimeout(() => {
         clearTimeout(window.isoTimeout);
         var noBoxOpen = $('.box.open').length == 0;
         window.isotopes.forEach(iso => {
            iso.arrange({
               filter: (el) => {
                  const textMatch = this.activeFilters.search === '' || $(el).find('.title, .status').text().toLowerCase().indexOf(this.activeFilters.search) > -1;
                  // const themeMatch = this.activeFilters.theme === '' || $(el).find('.theme').text() === this.activeFilters.theme;
                  const isTheory = this.activeFilters.onlyTheory==false || (this.activeFilters.onlyTheory==true && el.dataset.istheory=='true');
                  const themesMatch = this.activeFilters.themes.indexOf(el.dataset.theme) > -1;
                  const catsMatch = this.activeFilters.cats.indexOf(el.dataset.cat) > -1;
                  return isTheory && textMatch && themesMatch && catsMatch;
               }
            });
         });
         if(this.activeFilters.search || this.activeFilters.theme || this.activeFilters.cats.length<3) {
            $('.boxes-inner').addClass('filtered');
            $('#minimap').addClass('filt');
            // if(noBoxOpen && window.relators.length > 0) {
            //    window.relators[0].clearRelateds();
            // }
         } else {
            $('.boxes-inner').removeClass('filtered');
            $('#minimap').removeClass('filt');
         }
         // window.mainView.state.scale = 0.5;
         if(noBoxOpen && window.relators.length == 0) {
            if($('.filtered').length>0) {
               window.mainView.state.translateX = 0;
               window.mainView.state.translateY = 0;
               window.mainView.updateTransform();
               clearTimeout(window.isoTimeout);
               window.isoTimeout = setTimeout(() => {
                  if($('.box.open').length == 0 && window.relators.length == 0) {
                     window.isotopes.forEach(iso => {
                        iso.layout();
                     });
                  }
               }, 500);
            }
         }

         if(window.miniMap) window.miniMap.update();
      }, 100); // Debounce for performance
   }

   initSearch(){
      const self = this;
      $('input.search').on('keyup change', function(e){
         var val = $(e.target).val().toLowerCase();
         self.activeFilters.search = val;
         self.applyFilters();

         window.mainView.state.scale = 0.65;
         window.mainView.updateTransform();
         // console.log($(this).val());
         // var val = $(this).val().toLowerCase();

         // clearTimeout(window.searchBounce);
         // window.searchBounce = setTimeout(function(){
         //    window.isotopes.forEach(iso => {
         //       iso.arrange({
         //          // item element provided as argument
         //          filter: function( el ) {
         //             var text = $(el).text().toLowerCase();
         //             if(text.indexOf(val) > -1) return true;
         //             // return
         //             // return parseInt( number, 10 ) > 50;
         //          }
         //       });
         //    });
         // }, 350);
      });
   }

   _mapRange(value, in_min, in_max, out_min, out_max) {
      return (value - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
   }

   // // Multiplier adjustment
   // const multiplier = Math.max(0.5, zoomAdjust * 2); // This will affect the scaling factor
   
   // // Calculate mouse position as a percentage of the viewport dimensions
   // var xPercent = (event.pageX / window.innerWidth) * 100;
   // var yPercent = (event.pageY / window.innerHeight) * 100;
   
   // // Directly map to -100% to 100%, then adjust based on the multiplier
   // // This maps 0% to -100% and 100% to 100%, then applies the multiplier adjustment
   // xPercent = (xPercent - 50) * 3; // Base range from -100% to 100%
   // yPercent = (yPercent - 50) * 3; // Base range from -100% to 100%
   
   // // Apply the multiplier. The subtraction of 0.5 from the multiplier adjusts the base effect to start at 0.
   // // Then scale and recenter to 50%
   // xPercent = 50 + (xPercent * (multiplier - 0.5));
   // yPercent = 50 + (yPercent * (multiplier - 0.5));
   
   // var transformCss = `translateX(${xTranslate}%) translateY(${yTranslate}%) scale(${zoomAdjust}) `;


   initFilters(){
      const self = this;
      
      window.entries.cats.forEach(cat => {
         let catEl = $(`
            <label class="cat" data-cat="${cat}">
               <input type="checkbox" checked name="cat" value="${cat}">
               <span>${cat}</span>
            </label>`);
         $('.cats').append(catEl);
      });

      let catInputs = $('.cats input');

      $(catInputs).on('change', (e) => {
         // var isChecked = $(this).is(':checked');
         let actives = [];
         for(let i=0; i<catInputs.length;i++) {
            if($(catInputs[i]).is(':checked')){
               actives.push($(catInputs[i]).val());
            }
         }
         self.activeFilters.cats = actives;
         self.applyFilters();
      });

      // var options = '<option value="">All</option>';
      window.entries.themes.forEach(theme => {
         // options += `<option value="${t}">${t}</option>`;
         let themeEl = $(`
            <label class="theme" data-theme="${theme}">
               <input type="checkbox" checked name="theme" value="${theme}">
               <span>${theme}</span>
            </label>`);
            $('.theme-select').append(themeEl);
            self.activeFilters.themes.push(theme);
      });

      // $('.theme-select').append(options);


      $('.theme-select input').on('change', (e) => {
         let catInputs = $('.theme-select input');
         // var isChecked = $(this).is(':checked');
         let actives = [];
         for(let i=0; i<catInputs.length;i++) {
            if($(catInputs[i]).is(':checked')){
               actives.push($(catInputs[i]).val());
            }
         }
         self.activeFilters.themes = actives;
         self.applyFilters();
      });

      $('.only-theory').on('change', (e) => {
         self.activeFilters.onlyTheory = $(e.target).is(':checked');
         self.applyFilters();
      });

      // $('.theme-select').on('change', function(){
      //    var val = $(this).val();
      //    isotopes.forEach(iso => {
      //       iso.arrange({
      //          filter: function(e){
      //             return val=='' ? true : $(e).find('.theme').text()==val
      //          }
      //       });
      //    });
      // });
   }

   initIsotope(){
      window.isoArrangeCount = 0;
      window.isotopesInitialArrangementOk = false;

      $('.boxes-inner').each(function(){
         var boxes = this;
         var iso = new isotope( boxes, {
            // options...
            stagger: 0,
            transitionDuration: 200,
            itemSelector: '.box',
            layoutMode: 'masonry',
            masonry: {
               // gutter: 400,
               columnWidth: 40,
               isFitWidth: false
            },
            // isFitWidth: true,
            percentPosition: true,
            // packery: {
            //    gutter: 10
            // },
            getSortData: {
               theme: '[data-theme]',
               title: '.title',
            },
            sortBy: 'title',
            initLayout: true,
            // masonry: {
            //    percentPosition: true
            //    // columnWidth: 200
            // }
         });

         // Listen for arrangeComplete event
         iso.on('layout', function(items) {
            // iso.element.style.opacity = 0;
         });
         iso.on('layoutComplete', function(items) {
            // iso.element.style.opacity = 1;
            // if(window.relators.length>0) {
            //    window.relators[0].clearRelateds();
            // }
            for(var i=0;i<$('.hovering').length;i++){
               lastbox.alignRelatedBoxes($('.hovering').get(i));
            }
            if($('.box.open').length == 0) {
               $('.footer').addClass('in');
            }
            if(window.miniMap) window.miniMap.update();
         });
         iso.on('arrange', function(items) {
            // iso.element.style.opacity = 0;
            if(window.miniMap) window.miniMap.update();
         });
         iso.on('arrangeComplete', function(items) {
            if(window.miniMap) window.miniMap.update();
            // console.log(`%cArrange complete`, 'color: #f00');
            // items: an array of Isotope.Items that were laid out
            /*
            items.forEach(function(item) {
               item.element.style.width = '';
               // Directly set the style on item's element
               setTimeout(function(){
                  var outerWidth = item.element.offsetWidth; // Using offsetWidth as a stand-in for outerWidth
                  item.element.style.width = outerWidth + 'px';
               }, 1);
            });
            */

            window.isoArrangeCount++;
            
            if(window.isoArrangeCount >= window.isotopes.length && !window.isotopesInitialArrangementOk) {
               console.log('All isotopes arranged');
               isotopesInitialArrangementOk = true;
               if($('.box.open').length == 0) {
                  $('.footer').addClass('in');
               }
            }

            iso.element.style.opacity = 1;

            for(var i=0;i<$('.hovering').length;i++){
               lastbox.alignRelatedBoxes($('.hovering').get(i));
            }
         });

         // setTimeout(function(){
         //    iso.arrange();
         // }, 10);

         if(!window.isotopes) window.isotopes = [];
         window.isotopes.push(iso);
      });

      $('.view-as input').on('change', function(){
         var val = $(this).attr('data-view-as');
         $('content.boxes').attr('data-view-as', val);
         window.isotopes.forEach(iso => {
            iso.layout();
         });
      });

      // $('.group-by').on('change', function(){
      //    var val = $(this).val();
      //    isotopes.forEach(iso => {
      //       iso.arrange({
      //          sortBy: val
      //       });
      //    });
      // });

      $('.reorder').on('click', function(e){
         e.preventDefault();
         
         // window.mainView.state.scale;
         window.mainView.state.translateX = 0;
         window.mainView.state.translateY = 0;
         window.mainView.updateTransform();
         isotopes.forEach(iso => {
            iso.layout();
         });
      });

      $('.footer').addClass('in');
   }
}