export function isValidIsbn(str) {
   var sum,
   weight,
   digit,
   check,
   i;
   
   str = str.replace(/[^0-9X]/gi, '');
   
   if (str.length != 10 && str.length != 13) {
      return false;
   }
   
   if (str.length == 13) {
      sum = 0;
      for (i = 0; i < 12; i++) {
         digit = parseInt(str[i]);
         if (i % 2 == 1) {
            sum += 3*digit;
         } else {
            sum += digit;
         }
      }
      check = (10 - (sum % 10)) % 10;
      return (check == str[str.length-1]);
   }
   
   if (str.length == 10) {
      weight = 10;
      sum = 0;
      for (i = 0; i < 9; i++) {
         digit = parseInt(str[i]);
         sum += weight*digit;
         weight--;
      }
      check = (11 - (sum % 11)) % 11
      if (check == 10) {
         check = 'X';
      }
      return (check == str[str.length-1].toUpperCase());
   }
};

export function isValidDoi(string) {
   //Redundant. I know 
   var testKey = String(string);
   var DOIpattern = '\b(10[.][0-9]{4,}(?:[.][0-9]+)*/(?:(?!["&\'<>])\S)+)\b';
 
   var found = new RegExp(DOIpattern).test(testKey);
   return found
 }

 export function isValidUrl(urlString) {
   var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
 '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
 '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
 '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
 '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
 '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
return !!urlPattern.test(urlString);
}
