export default class About {
   constructor() {
      const inner = $('.about-text--inner');
      for(var i=0;i<20;i++) {
         if(!window.entries.entries[i]['about-hyperimage-atlas-2022']) break;
         let copy = window.entries.entries[i]['about-hyperimage-atlas-2022'].replaceAll('\n\n','<div class="spacer"></div>');
         inner.append(`<p>${copy}</p>`);
      }
      inner.append(`<div class="spacer"></div>`);
      
      for(var j=27;j<35;j++) {
         if(!window.entries.entries[j]['readme']) break;
         let copy2 = `${window.entries.entries[j]['readme'].replaceAll('\n\n','<div class="spacer"></div>')}`;
         inner.append(`<p class='copy'>${copy2}</p>`);
      }
      inner.append(`<br>`);
      
      for(var n=20;n<26;n++) {
         if(!window.entries.entries[n]['role']) break;
         let copy3 = `${window.entries.entries[n]['role']}: ${window.entries.entries[n]['first-name']} ${window.entries.entries[n]['last-name']}<br>`;
         inner.append(`<p class='copy'>${copy3}</p>`);
      }

      let copyright = `
         <p class='copy'>&copy; ${new Date().getFullYear()} Sheung Yiu.</p>
      `;
      inner.append(copyright);

      $('.about-link').click(function(e){
         if(!e.metaKey && !e.ctrlKey && !e.shiftKey) {
            e.preventDefault();
            $('body').removeClass('bib-open');
            $('body').toggleClass('about-open');
         }
      });

      $('.about--close').click(function(e){
         e.preventDefault();
         $('body').removeClass('about-open');
      });
   }
}