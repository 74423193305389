export default class miniMap {
   constructor() {
      this.canvas = null;
      this.ctx = null;
      this.articles = null;
      this.scale = null;

      this.init();

      this.init = this.init.bind(this);
      this.update = this.update.bind(this);
      this.handleMapClick = this.handleMapClick.bind(this);
   }

   init() {
      const t = this;
      console.log('MiniMap class initialized');
      if(!document.querySelector('.boxes-inner')) {
         setTimeout(() => {
            this.init();
         }, 500);
         return;
      }
      this.boxesInner = document.querySelector('.boxes-inner');
      this.canvas = document.getElementById('minimap');
      this.ctx = this.canvas.getContext('2d');
      this.main = document.querySelector('main');
      this.articles = document.querySelectorAll('.box');
      // Mini-map scale factor, relative to full DOM size
      this.scale = 0.03;

      window.minimapPause = false;
      t.timeouts = [];

      window.addEventListener('resize', t.update);
      this.canvas.addEventListener('click', t.handleMapClick);

      this.update();
   }

   update() {
      function mapNumber (number, inMin, inMax, outMin, outMax) {
         return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
     }
     
      const t = this;
      if(!t.boxesInner) return;
      t.canvas.width = t.boxesInner.getBoundingClientRect().width * t.scale;
      t.canvas.height = t.boxesInner.getBoundingClientRect().height * t.scale;
      // Clear canvas
      t.ctx.clearRect(0, 0, t.canvas.width, t.canvas.height);

      // calculate bounding box of all elements
      let maxLeft = Infinity;
      let maxTop = Infinity;
      let maxRight = -Infinity;
      let maxBottom = -Infinity;
      
      // Apply transform scale from the main container if necessary
      const translateX = window.mainView.state.translateX; // Taken from main transform translate
      const translateY = window.mainView.state.translateY; // Taken from main transform translate
      const mainScale = window.mainView.state.scale; // Taken from main transform scale
      
      let filterOffset = 0;
      let filterOffsetY = 0;
      const isFiltered = document.querySelector('.filtered');
      isFiltered ? filterOffset = -(t.canvas.width * .25) : filterOffset = 0;
      isFiltered ? filterOffsetY = -(t.canvas.height * .16) : filterOffsetY = 0;
      
      let offsetXXX = mapNumber(mainScale, 0.4, 1.5, -20, 6);
      let offsetYYY = mapNumber(mainScale, 0.4, 1.5, -6, 4);

      const offset = {
         // top: t.canvas.height * .5 - (t.canvas.height*0.075) - filterOffsetY,
         // left: t.canvas.width * .5 - (t.canvas.width*0.11) - filterOffset

         // map mainScale from -1.51 to 0.78 to offset the minimap
         
         top: t.canvas.height * .5 - (t.canvas.height*0.075) + filterOffsetY + offsetYYY, // - (($('#minimap').outerHeight() - t.canvas.height))
         left: t.canvas.width * .5 - (t.canvas.width*0.05) + filterOffset + offsetXXX // - (($('#minimap').outerWidth() - t.canvas.width))
         
         // top: t.canvas.height * .5 - (t.canvas.height * .25 * mainScale) * -.6, // - (t.canvas.height*0.075) - filterOffsetY,
         // left: t.canvas.width * .5 - (t.canvas.width * .25 * mainScale) * -.6, // - (t.canvas.width*0.11) - filterOffset
      };

      // Draw each element's position on the mini-map
      t.articles.forEach(article => {
         const rect = article.getBoundingClientRect();
         
         const x = (rect.left - translateX) * t.scale + offset.left;
         const y = (rect.top - translateY) * t.scale + offset.top;
         const width = rect.width * (Math.min(1.25, mainScale)) * t.scale;
         const height = rect.height * (Math.min(1.25, mainScale)) * t.scale;

         maxLeft = Math.min(maxLeft, rect.left);
         maxTop = Math.min(maxTop, rect.top);
         maxRight = Math.max(maxRight, rect.right);
         maxBottom = Math.max(maxBottom, rect.bottom);
         
         // t.ctx.lineWidth = 1;
         // t.ctx.strokeStyle = 'white';
         // t.ctx.strokeRect(x, y, width, height);
         let ac = article.classList;
         if(ac.contains('open') || ac.contains('hovering') || ac.contains('related')) {
            t.ctx.fillStyle = '#fff';
         } else {
            t.ctx.fillStyle = '#555';
         }
         t.ctx.fillRect(x, y, width, height);
      });

      // t.canvas.width = (maxRight - maxLeft) * t.scale;
      // t.canvas.height = (maxBottom - maxTop) * t.scale;
      
      // Draw the viewport rectangle
      const viewportWidth = window.innerWidth * t.scale;
      const viewportHeight = window.innerHeight * t.scale;
      const viewportX = (-t.main.getBoundingClientRect().left) * t.scale + offset.left;
      const viewportY = (-t.main.getBoundingClientRect().top) * t.scale + offset.top;
      
      // t.ctx.fillStyle = 'rgba(255, 255, 255, 0.4)';
      // t.ctx.fillRect(viewportX, viewportY, viewportWidth, viewportHeight);
      t.ctx.strokeStyle = '#f7bdc3';
      t.ctx.strokeRect(viewportX, viewportY, viewportWidth, viewportHeight);
   }

   handleMapClick(event) {
      const t = window.miniMap;

      if(window.minimapPause) return;
      window.minimapPause = true;

      // Get the click position relative to the canvas
      const rect = t.canvas.getBoundingClientRect();
      const clickX = event.clientX*1.15 - rect.left;
      const clickY = event.clientY*1.02 - rect.top;

      console.log(event.clientX, rect.left);

      // Get offset and scale values to convert mini-map click position to main viewport position
      let h = $(t.canvas).outerHeight();
      let w = $(t.canvas).outerWidth();

      var offset = {
         top: h * 0.5 - h * 0.15,
         left: (w * 0.5 - w * 0.11)
      };

      // Calculate where the click corresponds on the main content
      const relativeClickX = (clickX - offset.left) / t.scale;
      const relativeClickY = (clickY - offset.top) / t.scale;

      // Get the current translateX and translateY
      const currentTranslateX = window.mainView.state.translateX;
      const currentTranslateY = window.mainView.state.translateY;
      const mainScale = window.mainView.state.scale;
      // Calculate the center of the viewport in relation to the main content
      const viewportCenterX = (-currentTranslateX + window.innerWidth / 2) / mainScale;
      const viewportCenterY = (-currentTranslateY + window.innerHeight / 2) / mainScale;
   
      // Calculate the difference between the clicked position and the current viewport center
      const deltaX = relativeClickX - viewportCenterX;
      const deltaY = relativeClickY - viewportCenterY;
   
      // Adjust the translation values, accounting for scale
      const newTranslateX = currentTranslateX - deltaX * mainScale;
      const newTranslateY = currentTranslateY - deltaY * mainScale;
   
      // Set the new translation values
      window.mainView.state.translateX = newTranslateX;
      window.mainView.state.translateY = newTranslateY;
   
      window.transformRaf = requestAnimationFrame(() => {
         window.mainView.updateTransform();
         
         for(let i=0; i<5; i++) {
            setTimeout(() => {
               t.update();
               if(i==4) {
                  window.minimapPause = false;
               }
            }, 500 + i*50);
         }
      });

      // Update the mini-map after the transformation
      // window.miniMap.update(); // included in mainview.updateTransform()
   }
}