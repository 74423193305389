import drawAllLines from './mainViewDraw';

export default class mainView {
   constructor(el){
      const t = this;

      this.allowRender = false;
      
      this.state = {
          mousedown: null,
          mousedownX: 0,
          mousedownY: 0,
          mouseX: 0,
          mouseY: 0,
          translateX: 0,
          translateY: 0,
          scale: .8
      };


      // this.updateTransform();

      this.binds();

      // Canvas steez

      if(!this.canvas) {
         this.canvas = document.getElementById('lines-canvas');
         this.context = this.canvas .getContext('2d');
         this.container = $('.boxes-inner');
         this.canvas.width = this.container.outerWidth(true) * 2;
         let outerh = this.container.outerHeight(true);
         this.canvas.height = Math.max(window.innerHeight*3, outerh);
         // this.canvas.height = outerh < window.innerHeight ? outerh*3 : outerh;
         
         $(window).on('resize', function(){
            if(t.canvas){
               let outerW = $(t.container).outerWidth(true);
               let transX = Math.abs(window.mainView.state.translateX)*2.25 + window.innerWidth;
               let transXorWidth = Math.max(transX, outerW);
               t.canvas.width = transXorWidth;

               let outerH = $(t.container).outerHeight(true);
               let minCheck = Math.max(window.innerHeight*3, outerH);
               let transY = Math.abs(window.mainView.state.translateY)*2.25;
               t.canvas.height = minCheck + transY;
            }
         });
      }

      let previousTime = performance.now();
      let delta = 0;
      let interval = 1 / 30 * 1000; 

      this.animate = function animate() {
         requestAnimationFrame(t.animate);
         
         let currentTime = performance.now();
         let elapsedTime = currentTime - previousTime;
         previousTime = currentTime;
         
         delta += elapsedTime;
         
         if (delta > interval) {
            // if(t.allowRender) {
               drawAllLines(t.canvas, t.context);  // Draw all the lines in the current frame
               // render();
               // if(window.mainView) window.mainView.updateTransform();
               // if(window.miniMap) window.miniMap.update();
            // }
            
            delta = delta % interval; // Adjust delta to not lose time between frames
         }
      }
      this.animate = this.animate.bind(this);
   }

   binds() {
      const t = this;
      var state = this.state;

      $('body').on('mouseenter', () => {
         $('main').removeClass('mousedown zooming zooming-out zooming-int');
         window.preventClick = false;
         state.mousedown = false;
      }).on('mousedown', (event) => {
         if($(event.target).is('.box.open') || $(event.target).parents('.box.open').length>0) return;
         if($(event.target).is('.reset')) return;
         if($(event.target).is('img')) event.preventDefault();
         // if($('.box.open').length!==0) return;
         $('main').addClass('mousedown');
         state.mousedown = true;
         state.mouseX = event.pageX;
         state.mouseY = event.pageY;
         state.mousedownX = event.pageX;
         state.mousedownY = event.pageY;
         clearTimeout(window.clearPrevent);
      }).on('mouseup', () => {
         if (!state.mousedown) { return; }
         $('main').removeClass('mousedown');
         state.mousedown = false;
         window.clearPrevent = setTimeout(function(){
            window.preventClick = false;
         }, 10);
      }).on('mousemove', (event) => {
         if (!state.mousedown) { return; }
         if($('body').hasClass('bib-open')||$('body').hasClass('about-open')) return;

         clearTimeout(window.clearPrevent);
         window.preventClick = true;

         window.mouseovers.forEach(e => {
            clearTimeout(e);
         });
         $('.box:hover').addClass('no-hover');
         
         // Calculate movement delta
         let dx = event.pageX - state.mouseX;
         let dy = event.pageY - state.mouseY;
         
         // Update the state
         state.translateX += dx;
         state.translateY += dy;

         // Apply the transform
         window.transformRaf = requestAnimationFrame(() => {
            t.updateTransform();
         });

         window.ux.guideTexts.drag.classList.add('ok');
         
         // Update the last mouse position
         state.mouseX = event.pageX;
         state.mouseY = event.pageY;

         if(Math.abs(state.mousedownX - state.mouseX) > 10 || Math.abs(state.mousedownY - state.mouseY) > 10) {
            window.preventClick = true;
         }
      }).on('mousewheel', function(event) {
         // if($('.box.open').length!==0) return;

         event = event.originalEvent || event;

         if($(event.target).is('.box.open') || $(event.target).parents('.box.open').length>0){
            event.preventDefault();
            if($('.box.open').length!==0) {
               window.relators[0].centerBox($('.box.open').get(0));
            }
            return;
         }

         if($('body').hasClass('bib-open')||$('body').hasClass('about-open')) return;

         event.preventDefault();

         $('main').addClass('zooming');
         
         let zoomVal = ((event.deltaY || 0.0) * 0.001) * -1;
         
         // Calculate the new scale
         let newScale = Math.min(Math.max(0.4, state.scale + zoomVal), 1.5);
         

         // let adjustmentFactor = calculateAdjustmentFactor(state.scale, newScale);
         // console.log(adjustmentFactor);
         // Normalize translate values when zooming out
         if (newScale < state.scale) {

            $('main').removeClass('zooming-in').addClass('zooming-out');
            // state.translateX *= adjustmentFactor;
            // state.translateY *= adjustmentFactor;

            
            // state.translateX *= 0.95;
            // state.translateY *= 0.95;
         } else {
            $('main').removeClass('zooming-out').addClass('zooming-in');
            
            // Calculate the position to zoom towards
            let mouseX = event.pageX;
            let mouseY = event.pageY;
            // let mainOffset = $('main').offset();
            let localX = (mouseX - window.innerWidth*.5) * 1.05; // - mainOffset.left;
            let localY = (mouseY - window.innerHeight*.5) * 1.05; // - mainOffset.top;
            
            // Adjust translation to simulate zooming towards the mouse position
            let scaleFactor = (newScale / state.scale);
            // state.translateX -= (localX * scaleFactor - localX);
            // state.translateY -= (localY * scaleFactor - localY);

            // state.translateX -= (localX * scaleFactor - localX) * (3 - adjustmentFactor); // More aggressive when closer to 1
            // state.translateY -= (localY * scaleFactor - localY) * (3 - adjustmentFactor);
         }
         
         state.scale = newScale;
         
         // Apply the transform
         t.updateTransform();

         clearTimeout(window.removeZooming);
         window.removeZooming = setTimeout(function(){
            $('main').removeClass('zooming zooming-in zooming-out');
         }, 100);
      });

      // $(window).on('resize', function(){
      //    clearTimeout(window.resizeTimeout);
      //    window.resizeTimeout = setTimeout(function(){
      //       if(window.relators.length>0) {
      //          for(var i=0; i<window.relators.length; i++) {
      //             window.relators[i].alignRelatedBoxes(window.relators[i]._box);
      //          }
      //       }
      //    }, 1000);
      // });
   }

   updateTransform() {
      const state = this.state;

      // state.translateX = Math.max(state.translateX, -window.innerWidth);
      // state.translateX = Math.min(state.translateX, window.innerWidth);

      // state.translateY = Math.max(state.translateY, -window.innerHeight);
      // state.translateY = Math.min(state.translateY, window.innerHeight);

      var transformCss = `translate3d(${state.translateX}px, ${state.translateY}px, 0px) scale(${state.scale})`;
      $('main').css({
          'transform': transformCss
      });

      // this.allowRender = true;
      // clearTimeout(window.renderTimeout);
      // window.renderTimeout = setTimeout(() => {
      //    this.allowRender = false;
      // }, 1000);
      $(window).trigger('resize');
      if(window.miniMap && !window.minimapPause) window.miniMap.update();
   }
}